import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import LogoAlt from './LogoAlt';
import NavSearch  from './NavSearch';
import TopLinks  from './TopLinks';
import MenuContentTop  from './MenuContentTop';
import AvatarMenu  from '../../components/shared-components/AvatarMenu';
import NavProfile from './NavProfile';
import IconFallback from "../util-components/IconFallback";
import * as companyConst from 'constants/CompanyConstant';
import { env } from "../../configs/EnvironmentConfig";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import ReactShadowRoot from 'react-shadow-root';
import { GlobalNav, GlobalNavVariation, GlobalNavDropdownMenuItem } from "@activated-insights/global-nav";
import {
	CLICK_LOGO_EVENT_NAME,
	CLICK_LOGOUT_EVENT_NAME,
  } from "@activated-insights/global-nav";
import { toggleCollapsedNav, onMobileNavToggle } from '../../redux/actions/Theme';
import { onNewExperienceToogleValueChange } from '../../redux/actions/General';
import { isSignedUserAnHcpAdmin, getSelectedVantageCompanyData } from '../../redux/actions/Cip';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants/ThemeConstant';
import utils from '../../utils';
import UserService from '../../services/UserService';
import { 
	CloudUploadOutlined
  } from '@ant-design/icons';
import { useHistory } from "react-router";

const { Header } = Layout;

export const HeaderNav = props => {
	const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme,
		 	 onNewExperienceToogleValueChange, isMainGeneralToogleVisible, isMainGeneralToogleValueOn,
		    isHcpAdmin, getSelectedVantageCompanyData, selectedAgencyData, pathLocation } = props;
	const [searchActive, setSearchActive] = useState(false)
	const [searchVisible, setSearchVisible] = useState(false)
	const globalNavRef = useRef();
	const history = useHistory();
	
	const onSearchClose = () => {
		setSearchActive(false)
	}

	const onToggle = () => {
	  if(!isMobile) {
	    toggleCollapsedNav(!navCollapsed)
	  } else {
	    onMobileNavToggle(!mobileNav)
	  }
	}

	const onExperienceToogle = (event) => {	
		// You need here the redux method for toogle in General	
		onNewExperienceToogleValueChange(event.target.checked);		
		if(event.target.checked){
			if (env.IS_QUICKSIGHT_COMPONENT_ON &&
				(selectedAgencyData?.CompanyGroupIds?.includes(companyConst.RIGHT_AT_HOME_USA_DW_COMPANY_GROUP_ID)) &&
				 selectedAgencyData?.IsCompanyGroup === false) {
					//// HANDLEEEEEE DOUBLE REQUEST FOR QUICKSIGHT IF NEEDED

			}

		}		
	
	}

	const isNavTop = navType === NAV_TYPE_TOP ? true : false
	const mode = ()=> {
		if(!headerNavColor) {
			return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
		}
		return utils.getColorContrast(headerNavColor)
	}
	const navMode = mode()
	const getNavWidth = () => {
		if(isNavTop || isMobile) {
			return '0px'
		}
		if(navCollapsed) {
			return `${SIDE_NAV_COLLAPSED_WIDTH}px`
		} else {
			return `${SIDE_NAV_WIDTH}px`
		}
	}

	const handleInterceptLinks = (e) => {
		    const href = e.target?.closest('a')?.getAttribute('href');		
		    if (href?.startsWith('/cip/training') || href?.startsWith('/cip/reputation') || href?.startsWith('/cip/experience')) {		
		        e.preventDefault();					
		        history.push(href);
		    }		
		}

	useEffect(() => {
		if(!isMobile) {
			onSearchClose()
		}
		getSelectedVantageCompanyData();	
		
	    if (globalNavRef?.current) {		
		        globalNavRef?.current.addEventListener(CLICK_LOGO_EVENT_NAME, function (e) {				
				onToggle();
	        })
	    }
	})

	useEffect(() => {  
	        globalNavRef?.current.addEventListener(CLICK_LOGOUT_EVENT_NAME, function (e) {				
				// Log out the proper way no redirection handle it here		
				UserService.doLogout();
				e.detail.originalEvent.preventDefault();
       		})	
		}, [selectedAgencyData?.CompanyId]); // <- run this only once
		
	if(env.EXTERNAL_ENCAPSULATED_GLOBAL_NAV_ON){
		return (
			<div className="external-global-nav-wrapper"> 
			<div ref={globalNavRef}>
			<ReactShadowRoot> 
			  <link rel="stylesheet" href="https://hcp-engineering-public-access.s3.us-east-1.amazonaws.com/product-global-navigation/1.0.34/global-nav-web-component.css" /> 
			  <div style={{  zIndex:1000, position: 'fixed', left:0, right:0 }} onClick={handleInterceptLinks}>
				<GlobalNav 
				canAccessHcpExperience 
				canAccessHcpReputation 
				canAccessHcpTraining 				
				  hosts={{
					    hcpExperienceAppHost: "",					
					    hcpReputationAppHost: "",					
					    hcpTrainingAppHost: "",					
					  }}
				variation={GlobalNavVariation.HCP_EXPERIENCE} 
				companyName={selectedAgencyData?.CompanyName ?? "Demo Agency"} 
				userName={UserService.getGivenName() ?? UserService.getUsername()}
				profileDropdownChildren={				
							<GlobalNavDropdownMenuItem as="a"
							iconStart={<span style={{fontSize:24}}><CloudUploadOutlined /></span>}
							onClick={(e) => {
							e.preventDefault();
								if(selectedAgencyData?.IsCompanyGroup){
									history.push("/cip/file-upload-corp");									
								}else{
									history.push("/cip/file-upload-provider");									
								}									
							}}
							>
							File Uploads
							</GlobalNavDropdownMenuItem>									      
						}
					/> 
			  </div>
			  </ReactShadowRoot>
			</div>
			<div className="toggle-global-nav-wrapper">
			{
			(isMainGeneralToogleVisible) &&
			<label>
				<div>	
				<span id="global-toggle-title" className='label-text'>New Experience</span>													
					<Toggle
						defaultChecked={isMainGeneralToogleValueOn}
						onChange={(event) => onExperienceToogle(event)}
						className='new-experience-toggle'
						icons={{
							checked: "On",
							unchecked: "Off",
							}}
						/>					
				</div>				
			</label>
			}
			</div>
		</div>
		)
	}else{
		return (		
			<Header className="app-header app-header--primary">
				<div className="app-header-wrapper">
					<LogoAlt />
	
					<MenuContentTop localization={false} />		
	
					<NavProfile />
				</div>			
				
				{
				(isMainGeneralToogleVisible) &&
				<label>
					<div id="toggle-wrapper">	
					<span id="toggle-title" className='label-text'>New Experience</span>													
						<Toggle
							defaultChecked={isMainGeneralToogleValueOn}
							onChange={(event) => onExperienceToogle(event)}
							className='new-experience-toggle'
							icons={{
								checked: "On",
								unchecked: "Off",
							  }}
							/>					
					</div>				
				</label>
				}
	
				{/* TODO This is the lower navigation that we will release feature by feature as its ready*/}
				<div className={`app-header-wrapper-alt ${searchVisible ? "active" : ""}`}>
					<button className="btn-burger" onClick={() => {onToggle()}}>
						<span></span>
						
						<span></span>
						
						<span></span>
					</button>
	
					{/* <button className="btn-search" onClick={() => setSearchVisible(!searchVisible)}>					
						<IconFallback path={"/img/others/ico-search.png"} />
					</button>
	
					<NavSearch active={searchActive} close={onSearchClose}/> */}
	
					{/* <TopLinks /> */}
				</div>
			</Header>
		)
	}
	

}

const mapStateToProps = ({ theme, cip, general}) => {
	const {isSignedUserAnHcpAdmin, isHcpAdmin, getSelectedVantageCompanyData, selectedAgencyData,
		   isSubscribedToThisService, logFeatureUserInteraction} = cip;

	const { onNewExperienceToogleValueChange, isMainGeneralToogleVisible, isMainGeneralToogleValueOn} = general

	const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } =  theme;

	return { onNewExperienceToogleValueChange, isMainGeneralToogleVisible, isMainGeneralToogleValueOn, navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, isSignedUserAnHcpAdmin,
		     isHcpAdmin, getSelectedVantageCompanyData, selectedAgencyData,
			 isSubscribedToThisService, logFeatureUserInteraction }
};

export default connect(mapStateToProps, {onNewExperienceToogleValueChange, toggleCollapsedNav, onMobileNavToggle,
	 isSignedUserAnHcpAdmin, getSelectedVantageCompanyData})(HeaderNav);