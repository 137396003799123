const dev = {
  DATAWAREHOUSE_API_ENDPOINT: 'https://dev.warehouse.homecarepulse.com',
  KC_ENDPOINT_URL: 'https://sso.homecarepulse.com',
  DECISIONS_API_ENDPOINT_URL: 'https://dev.app.homecarepulse.com',
  VANTAGE_URI: 'https://vantage.dev.homecarepulse.com',
  VANTAGE_URI_PRINTABLE_FALSE: 'https://vantage.private.dev.homecarepulse.com?keep_printable=false',
  DEV_GUESS_ENDPOINT_USER_ID: 'CIP-GUEST-0000',
  DEV_GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR',
  GUESS_ENDPOINT_USER_ID: 'CIP-GUEST-0000',
  GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR',
  KC_REALM:'hcp',
  PUBLIC_ACTIVATED_INSIGHTS_APP_HOST: 'https://activatedinsights.com',
  PUBLIC_PINNACLE_RETAIN_APP_HOST: 'https://app.retainyouremployees.com',
  PUBLIC_PINNACLE_QI_APP_HOST: "https://my.pinnacleqi.com",
  // DECISIONS_API_ENDPOINT_URL: 'https://app.homecarepulse.com',
  // GUESS_ENDPOINT_USER_ID: 'PD-CIP-GUEST-0000',
  // GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR!'
  HUBSPOT_REGION: "na1",
  HUBSPOT_PORTAL_ID: '2742328',
  HUBSPOT_FORM_ID: '5afd8bb3-8ec4-482f-b664-e3795cc242bb',
  IS_BILLING_PROFILE_OPTION_ON: false,
  IS_EDIT_PROFILE_OPTION_ON: false,
  IS_NEW_USER_APP_OPTION_ON: false,  
  IS_CORRECTIONS_TABLE_FEATURE_ON: false,
  IS_POC_UI_COMPONENT_ON: true,
  IS_QUICKSIGHT_COMPONENT_ON: true,
  IS_NATIVE_GLOBAL_NAV_FEATURE_ON: false,
  EXTERNAL_ENCAPSULATED_GLOBAL_NAV_ON: true,
  IS_LOCATION_POC_UI_COMPONENT_ON: true,
  IS_DNC_REPORT_ON:true,
  IS_NEW_INFO_DASHBOARD_ON:true,
  ENVIROMENT: 'dev',  
};

const stage = {
  DATAWAREHOUSE_API_ENDPOINT: 'https://warehouse.homecarepulse.com',
  KC_ENDPOINT_URL: 'https://sso.homecarepulse.com',
  DECISIONS_API_ENDPOINT_URL: 'https://app.homecarepulse.com',
  VANTAGE_URI: 'https://vantage.homecarepulse.com',
  VANTAGE_URI_PRINTABLE_FALSE: 'https://vantage.homecarepulse.com?keep_printable=false',
  GUESS_ENDPOINT_USER_ID: 'PD-CIP-GUEST-0000',
  GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR!',
  KC_REALM:'hcp',
  PUBLIC_ACTIVATED_INSIGHTS_APP_HOST: 'https://activatedinsights.com',
  PUBLIC_PINNACLE_RETAIN_APP_HOST: 'https://app.retainyouremployees.com',
  PUBLIC_PINNACLE_QI_APP_HOST: "https://my.pinnacleqi.com",
  IS_BILLING_PROFILE_OPTION_ON: false,
  IS_EDIT_PROFILE_OPTION_ON: false,
  IS_NEW_USER_APP_OPTION_ON: false,  
  IS_CORRECTIONS_TABLE_FEATURE_ON: false,
  IS_POC_UI_COMPONENT_ON: true,
  IS_QUICKSIGHT_COMPONENT_ON: true,
  IS_NATIVE_GLOBAL_NAV_FEATURE_ON: false,
  EXTERNAL_ENCAPSULATED_GLOBAL_NAV_ON: true,
  IS_LOCATION_POC_UI_COMPONENT_ON: true,
  HUBSPOT_REGION: "na1",
  HUBSPOT_PORTAL_ID: '2742328',
  HUBSPOT_FORM_ID: '5afd8bb3-8ec4-482f-b664-e3795cc242bb',
  DEV_GUESS_ENDPOINT_USER_ID: 'CIP-GUEST-0000',
  DEV_GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR',  
  IS_DNC_REPORT_ON:true,
  IS_NEW_INFO_DASHBOARD_ON:true,
  ENVIROMENT: 'stage',  
};

const prod = {
  DATAWAREHOUSE_API_ENDPOINT: 'https://warehouse.homecarepulse.com',
  KC_ENDPOINT_URL: 'https://sso.homecarepulse.com',
  DECISIONS_API_ENDPOINT_URL: 'https://app.homecarepulse.com',
  VANTAGE_URI: 'https://vantage.homecarepulse.com',
  VANTAGE_URI_PRINTABLE_FALSE: 'https://vantage.homecarepulse.com?keep_printable=false',
  GUESS_ENDPOINT_USER_ID: 'PD-CIP-GUEST-0000',
  GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR!',
  KC_REALM:'hcp',
  PUBLIC_ACTIVATED_INSIGHTS_APP_HOST: 'https://activatedinsights.com',
  PUBLIC_PINNACLE_RETAIN_APP_HOST: 'https://app.retainyouremployees.com',
  PUBLIC_PINNACLE_QI_APP_HOST: "https://my.pinnacleqi.com",
  IS_BILLING_PROFILE_OPTION_ON: false,
  IS_EDIT_PROFILE_OPTION_ON: false,
  IS_NEW_USER_APP_OPTION_ON: false,  
  IS_CORRECTIONS_TABLE_FEATURE_ON: false,
  IS_POC_UI_COMPONENT_ON: true,
  IS_QUICKSIGHT_COMPONENT_ON: false,
  IS_NATIVE_GLOBAL_NAV_FEATURE_ON: false,
  EXTERNAL_ENCAPSULATED_GLOBAL_NAV_ON: true,
  IS_LOCATION_POC_UI_COMPONENT_ON: true,
  HUBSPOT_REGION: "na1",
  HUBSPOT_PORTAL_ID: '2742328',
  HUBSPOT_FORM_ID: '5afd8bb3-8ec4-482f-b664-e3795cc242bb',
  DEV_GUESS_ENDPOINT_USER_ID: 'CIP-GUEST-0000',
  DEV_GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR',    
  IS_DNC_REPORT_ON:true,
  IS_NEW_INFO_DASHBOARD_ON:false,
  ENVIROMENT: 'prod'
};


const local = {
  // DATAWAREHOUSE_API_ENDPOINT: 'https://dev.warehouse.homecarepulse.com',
  // KC_ENDPOINT_URL: 'https://local.homecarepulse.com:8443',  
  // DECISIONS_API_ENDPOINT_URL: 'https://dev.app.homecarepulse.com',
  // GUESS_ENDPOINT_USER_ID: 'CIP-GUEST-0000',
  // GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR',
  // VANTAGE_URI: 'https://dev.homecarepulse.com/',
  // VANTAGE_URI_PRINTABLE_FALSE: 'https://dev.homecarepulse.com?keep_printable=false',
  // KC_REALM:'hcp',
  // PUBLIC_ACTIVATED_INSIGHTS_APP_HOST: 'https://activatedinsights.com',
  // PUBLIC_PINNACLE_RETAIN_APP_HOST: 'https://app.retainyouremployees.com',
  // PUBLIC_PINNACLE_QI_APP_HOST: "https://my.pinnacleqi.com",
  HUBSPOT_REGION: "na1",
  HUBSPOT_PORTAL_ID: '2742328',
  HUBSPOT_FORM_ID: '5afd8bb3-8ec4-482f-b664-e3795cc242bb',
  IS_BILLING_PROFILE_OPTION_ON: false,
  IS_EDIT_PROFILE_OPTION_ON: false,
  IS_NEW_USER_APP_OPTION_ON: true,  
  IS_CORRECTIONS_TABLE_FEATURE_ON: true,
  IS_POC_UI_COMPONENT_ON: true,
  IS_QUICKSIGHT_COMPONENT_ON: true,
  IS_NATIVE_GLOBAL_NAV_FEATURE_ON: false,
  EXTERNAL_ENCAPSULATED_GLOBAL_NAV_ON: true,
  IS_LOCATION_POC_UI_COMPONENT_ON: true,
  // DEV_GUESS_ENDPOINT_USER_ID: 'CIP-GUEST-0000',
  // DEV_GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR', 

  DATAWAREHOUSE_API_ENDPOINT: 'https://warehouse.homecarepulse.com',
  KC_ENDPOINT_URL: 'https://sso.homecarepulse.com',
  VANTAGE_URI: 'https://vantage.homecarepulse.com',
  VANTAGE_URI_PRINTABLE_FALSE: 'https://vantage.homecarepulse.com?keep_printable=false',
  DECISIONS_API_ENDPOINT_URL: 'https://app.homecarepulse.com',
  GUESS_ENDPOINT_USER_ID: 'PD-CIP-GUEST-0000',
  GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR!',
  KC_REALM:'hcp',
  PUBLIC_ACTIVATED_INSIGHTS_APP_HOST: 'https://activatedinsights.com',
  PUBLIC_PINNACLE_RETAIN_APP_HOST: 'https://app.retainyouremployees.com',
  PUBLIC_PINNACLE_QI_APP_HOST: "https://my.pinnacleqi.com",
  // IS_BILLING_PROFILE_OPTION_ON: false,
  // IS_EDIT_PROFILE_OPTION_ON: false,
  // IS_NEW_USER_APP_OPTION_ON: false,  
  // IS_CORRECTIONS_TABLE_FEATURE_ON: false,
  // IS_POC_UI_COMPONENT_ON: true,
  // IS_NATIVE_GLOBAL_NAV_FEATURE_ON: false,
  // EXTERNAL_ENCAPSULATED_GLOBAL_NAV_ON: true,
  HUBSPOT_REGION: "na1",
  HUBSPOT_PORTAL_ID: '2742328',
  HUBSPOT_FORM_ID: '5afd8bb3-8ec4-482f-b664-e3795cc242bb',
  DEV_GUESS_ENDPOINT_USER_ID: 'CIP-GUEST-0000',
  DEV_GUESS_PWD: 'aC4PSbw6KBpUUy0tiuhe0cNR',   
  IS_DNC_REPORT_ON:true,
  IS_NEW_INFO_DASHBOARD_ON:false,
  ENVIROMENT: 'local'
};

const getEnv = () => {
  const enviroment = "dev";
  // const enviroment = "prod";
  // const enviroment = "dev";
  // const enviroment = "stage";
  // const enviroment = "local";
	switch (enviroment) {
		case 'dev':
			return dev
		case 'prod':
			return prod
    case 'stage':
      return stage
		case 'local':
			return local
		default:
			break;
	}
}

export const env = getEnv()
