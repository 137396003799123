import { Tag } from 'antd';
import { ApartmentOutlined, HomeOutlined } from "@ant-design/icons";
import * as companyConst from 'constants/CompanyConstant';


export const handlePercentageIfNaN = (percentage) => {
  return isNaN(percentage) ? '-' : percentage;
}

export const getMergedObjectArrays = async (arrayA, arrayB) => {
  for (const itemA of arrayA) {
    const matchingItemB = arrayB?.find((itemB) => itemB.CompanyId === itemA.CompanyId);
    if (matchingItemB) {
      itemA.CompanyGroupName = matchingItemB.CompanyGroupName;
      itemA.CompanyId_Parent = matchingItemB.CompanyId_Parent;
      itemA.CompanyId_Parent_Parent = matchingItemB.CompanyId_Parent_Parent;
      itemA.CompanyGroupName_Parent = matchingItemB.CompanyGroupName_Parent;
    }
  }
  return arrayA;
}

const getAllUnfilteredSurveysIds = async (surveysResponses) => {
  return surveysResponses?.map(a => a?.SurveyResponseId);
}

const getuniqueUnfilteredSurveysIds = async (allUnfilteredSurveysIds) => {
  return allUnfilteredSurveysIds?.filter((item, index) => allUnfilteredSurveysIds?.indexOf(item) === index);
}

export const formatSurveyDate = (surveyDate) => {
  if(surveyDate){
    return new Date(surveyDate)?.toLocaleDateString('en-us', { month: "numeric", day: "numeric", year: "numeric" });
  }
  return null;
}

const replaceStubbedWithCompanyName = (text, companyName) => {
  return text?.replace("[provider name]", companyName);
}

export const handleInternalConfigurationForSurveyResponses = async (companySyncSurveyResponses) => {
  let surveyResultsGroupedById = [];
  if (companySyncSurveyResponses) {
    let allUnfilteredSurveysIds = await getAllUnfilteredSurveysIds(companySyncSurveyResponses);
    let uniqueUnfilteredSurveysIds = await getuniqueUnfilteredSurveysIds(allUnfilteredSurveysIds);

    for (let i = 0; i < uniqueUnfilteredSurveysIds?.length; i++) {

      const filteredSurveys = companySyncSurveyResponses.filter(obj => {
        return obj.SurveyResponseId === uniqueUnfilteredSurveysIds[i];
      });

      for (let j = 0; j < filteredSurveys?.length; j++) {
        if (filteredSurveys[j]?.Language_QuestionText?.includes("[provider name]")) {
          filteredSurveys[j].Language_QuestionText = replaceStubbedWithCompanyName(filteredSurveys[j]?.Language_QuestionText, filteredSurveys[j]?.CompanyName);
        }
      }

      // For NPS Survey display
      const filteredRecommendedProvider = filteredSurveys?.filter(obj => {
        return obj.TagId === 'Recommend Provider';
      });

      // For OSAT Display
      const filteredNumericValuedQuestions = filteredSurveys?.filter(obj => {
        return (obj?.ValueBoolean === null && obj?.ValueNumeric !== null);
      });

      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;
      let tmp;
      let highestScoredTag;
      let lowestScoredTag;
      let tmpTag;
      for (let j = 0; j < filteredNumericValuedQuestions.length; j++) {
        tmp = filteredNumericValuedQuestions[j]?.ValueNumeric;
        tmpTag = filteredNumericValuedQuestions[j]?.TagId;

        if (tmp < lowest) {
          lowest = tmp;
          lowestScoredTag = tmpTag;
        }

        if (tmp > highest) {
          highest = tmp;
          highestScoredTag = tmpTag;
        }
      }

      surveyResultsGroupedById.push(
        {
          surveyId: uniqueUnfilteredSurveysIds[i],
          surveyDate: filteredSurveys[0].StartedAt,
          contactName: filteredSurveys[0].FullName,
          contactRelationship: filteredSurveys[0].ContactRelationId,
          headerSurveyResult: filteredRecommendedProvider,
          allFurtherSurveyResults: filteredSurveys,
          highestSurveyScore: highest,
          lowestSurveyScore: lowest,
          highestScoredSurveyTag: highestScoredTag,
          lowestScoredSurveyTag: lowestScoredTag,
          osat: (Math.round((filteredRecommendedProvider[0]?.OverallSatisfactionScore + Number.EPSILON) * 100) / 100)
        });
    }

    return surveyResultsGroupedById;

  }
  return surveyResultsGroupedById;
}

export const handleInternalConfigurationForClientsListForCompany = async (rawClientList) => {
  let clientsListReportConfiguration = {};
  let clientsResults = [];

  if (rawClientList) {
    // Shape Survey objects for display
    let firstNameFilter = [];
    let firstNameTracker = [];
    let lastNameFilter = [];
    let lastNameTracker = [];
    let statusFilter = [];
    let statusTracker = [];
    let callStatusFilter = [];
    let callStatusTracker = [];
    let admittedDateFilter = [];
    let admittedDateTracker = [];
    let dischargeDateFilter = [];
    let dischargeDateTracker = [];
    let companyNameFilter = [];
    let companyNameTracker = [];


    for (let i = 0; i < rawClientList?.length; i++) {

      const startedformattedDate = formatSurveyDate(rawClientList[i]?.ContactTermStartedAt);
      const endformattedDate = (rawClientList[i]?.ContactTermEndedAt === "infinity") ? 'Current' : formatSurveyDate(rawClientList[i]?.ContactTermEndedAt);
      const responsablePartyPrimaryPhoneNumber = rawClientList[i]?.RespondentPhone !== null ? rawClientList[i]?.RespondentPhone
        : rawClientList[i]?.RespondentPhone1 !== null
          ? rawClientList[i]?.RespondentPhone1
          : rawClientList[i]?.RespondentPhone2;
      const callStatus = rawClientList[i]?.DoNotContact === true ? "Do Not Contact" : "Can Contact";

      clientsResults.push(
        {
          key: i,
          firstName: rawClientList[i]?.FirstName, // First Name
          lastName: rawClientList[i]?.LastName, // Last Name
          primaryPhoneNumber: rawClientList[i]?.Phone1 ?? rawClientList[i]?.Phone2, // Client Phone
          email: rawClientList[i]?.Email, // Client Email
          companyName: rawClientList[i]?.CompanyName, // Office Name
          responsablePartyName: rawClientList[i]?.RespondentFullName, // respondent full name
          responsablePartyPrimaryPhoneNumber: responsablePartyPrimaryPhoneNumber,
          responsablePartyEmail: rawClientList[i]?.RespondentEmail,
          status: rawClientList[i]?.SurveyTypeId,
          callStatus: callStatus,
          admittedDate: startedformattedDate,
          dischargeDate: endformattedDate
        });

      if ((startedformattedDate && !admittedDateTracker.includes(startedformattedDate))) {
        admittedDateFilter.push({
          text: startedformattedDate,
          value: startedformattedDate
        });
        admittedDateTracker.push(startedformattedDate);
      }

      if ((startedformattedDate && !dischargeDateTracker.includes(endformattedDate))) {
        dischargeDateFilter.push({
          text: endformattedDate,
          value: endformattedDate
        });
        dischargeDateTracker.push(endformattedDate);
      }

      if (!callStatusTracker.includes(callStatus)) {
        callStatusFilter.push({
          text: callStatus,
          value: callStatus
        });
        callStatusTracker.push(callStatus);
      }

      if (!statusTracker.includes(rawClientList[i]?.SurveyTypeId)) {
        statusFilter.push({
          text: rawClientList[i]?.SurveyTypeId,
          value: rawClientList[i]?.SurveyTypeId
        });
        statusTracker.push(rawClientList[i]?.SurveyTypeId);
      }

      if ((rawClientList[i]?.FirstName && !firstNameTracker.includes(rawClientList[i]?.FirstName))) {
        firstNameFilter.push({
          text: rawClientList[i]?.FirstName,
          value: rawClientList[i]?.FirstName
        });
        firstNameTracker.push(rawClientList[i]?.FirstName);
      }

      if ((rawClientList[i]?.LastName && !lastNameTracker.includes(rawClientList[i]?.LastName))) {
        lastNameFilter.push({
          text: rawClientList[i]?.LastName,
          value: rawClientList[i]?.LastName
        });
        lastNameTracker.push(rawClientList[i]?.LastName);
      }

      if ((rawClientList[i]?.CompanyName && !companyNameTracker.includes(rawClientList[i]?.CompanyName))) {
        companyNameFilter.push({
          text: rawClientList[i]?.CompanyName,
          value: rawClientList[i]?.CompanyName
        });
        companyNameTracker.push(rawClientList[i]?.CompanyName);
      }
    }

    const clientsColumns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        editable: false,
        filters: [
          ...firstNameFilter
        ],
        onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
        sorter: (a, b) => a.firstName.length - b.firstName.length,
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        editable: false,
        filters: [
          ...lastNameFilter
        ],
        onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
        sorter: (a, b) => a.lastName.length - b.lastName.length,
      },
      {
        title: 'Primary Phone Number',
        dataIndex: 'primaryPhoneNumber',
        editable: false,
        onFilter: (value, record) => record.primaryPhoneNumber?.indexOf(value) === 0,
        sorter: (a, b) => a.primaryPhoneNumber.length - b.primaryPhoneNumber.length,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        editable: false,
        sorter: (a, b) => a.email.length - b.email.length,
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        editable: false,
        sorter: (a, b) => a.companyName.length - b.companyName.length,
      },
      {
        title: 'Responsable Party Name',
        dataIndex: 'responsablePartyName',
        editable: false,
        sorter: (a, b) => a.responsablePartyName.length - b.responsablePartyName.length,
      },
      {
        title: 'Responsable Party Email',
        dataIndex: 'responsablePartyEmail',
        editable: false,
        sorter: (a, b) => a.responsablePartyEmail.length - b.responsablePartyEmail.length,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        editable: false,
        filters: [
          ...statusFilter
        ],
        sorter: (a, b) => a.status.length - b.status.length,
      },
      {
        title: 'Call Status',
        dataIndex: 'callStatus',
        editable: false,
        filters: [
          ...callStatusFilter
        ],
        sorter: (a, b) => a.callStatus.length - b.callStatus.length,
      },
      {
        title: 'Admitted Date',
        dataIndex: 'admittedDate',
        editable: false,
        filters: [
          ...admittedDateFilter
        ],
        filterMode: 'menu',
        filterSearch: true,
        onFilter: (value, record) => record.admittedDate.startsWith(value),
        sorter: (a, b) => new Date(a.admittedDate) - new Date(b.admittedDate),
      },
      {
        title: 'Discharge Date',
        dataIndex: 'dischargeDate',
        editable: false,
        filters: [
          ...dischargeDateFilter
        ],
        filterMode: 'menu',
        filterSearch: true,
        onFilter: (value, record) => record.dischargeDate.startsWith(value),
        sorter: (a, b) => new Date(a.dischargeDate) - new Date(b.dischargeDate),
      }
    ];


    clientsListReportConfiguration = {
      clientsResults: clientsResults,
      clientsColumns: clientsColumns
    }

    console.log("YOUOUOUOU");
    console.log(rawClientList);

    return clientsListReportConfiguration;
  }
  return clientsListReportConfiguration;
}

export const filterByAssignedPropertyArray = async (data, arrayOffilteredProperties) => {
  return data.filter(item => arrayOffilteredProperties.includes(item?.status));
}

export const handleInternalConfigurationForClientsListForVantageCompany = async (rawClientList) => {

  // {
  //   "stg_Name": "StringValue",
  //   "interviewee_First": "StringValue",
  //   "interviewee_Last": "StringValue",
  //   "interviewee_Phone1": "StringValue",
  //   "interviewee_Phone2": "StringValue",
  //   "interviewee_Email": "StringValue",
  //   "preferred_surveymethod_id": "StringValue",
  //   "sm_Type": "StringValue",
  //   "is_Text": "StringValue",
  //   "interviewee_DNC": "StringValue",
  //   "interviewee_BeginDate": "StringValue",
  //   "interviewee_EndDate": "StringValue",
  //   "intervieweesrcstatus_id": "StringValue",
  //   "iss_Text": "StringValue",
  //   "call_status": "StringValue",
  //   "interviewee_SelfResp": false,
  //   "resp_Name": "StringValue",
  //   "resp_Phone1": "StringValue",
  //   "resp_Phone1Ext": "StringValue",
  //   "resp_Phone2": "StringValue",
  //   "resp_Phone2Ext": "StringValue",
  //   "resp_Email": "StringValue",
  //   "id": "StringValue",
  //   "node_id": "StringValue",
  //   "intervieweestatus_id": "StringValue"
  // }

  let clientsListReportConfiguration = {};
  let clientsResults = [];

  if (rawClientList) {
    // Shape Survey objects for display
    let firstNameFilter = [];
    let firstNameTracker = [];
    let lastNameFilter = [];
    let lastNameTracker = [];
    let statusFilter = [];
    let statusTracker = [];
    let callStatusFilter = [];
    let callStatusTracker = [];
    let admittedDateFilter = [];
    let admittedDateTracker = [];
    let dischargeDateFilter = [];
    let dischargeDateTracker = [];
    let companyNameFilter = [];
    let companyNameTracker = [];
    let preferredMethodFilter = [];
    let preferredMethodTracker = [];

    // Default Filters
    let defaultColumnFilters = {
      setPreRenderFilteres: true,
      status: ["Active", "New Hire", "New Client", "Needs Discharge Interview", "Discharged", "Pending"]
    }


    for (let i = 0; i < rawClientList?.length; i++) {

      const startedformattedDate = formatSurveyDate(rawClientList[i]?.interviewee_BeginDate);
      const endformattedDate = (rawClientList[i]?.interviewee_EndDate === "0000-00-00") ? 'Current' : formatSurveyDate(rawClientList[i]?.interviewee_EndDate);
      const responsablePartyPrimaryPhoneNumber =
        rawClientList[i]?.resp_Phone1 !== null
          ? rawClientList[i]?.resp_Phone1Ext !== null
            ? `${rawClientList[i]?.resp_Phone1} Ext:${rawClientList[i]?.resp_Phone1Ext}`
            : rawClientList[i]?.resp_Phone1
          : rawClientList[i]?.resp_Phone2 !== null
            ? rawClientList[i]?.resp_Phone2Ext !== null
              ? `${rawClientList[i]?.resp_Phone2} Ext:${rawClientList[i]?.resp_Phone2Ext}`
              : rawClientList[i]?.resp_Phone2
            : "";


      clientsResults.push(
        {
          key: i,
          firstName: rawClientList[i]?.interviewee_First, // First Name
          lastName: rawClientList[i]?.interviewee_Last, // Last Name
          primaryPhoneNumber: rawClientList[i]?.interviewee_Phone1 ?? rawClientList[i]?.interviewee_Phone2, // Client Phone
          email: rawClientList[i]?.interviewee_Email?.includes("@") ? rawClientList[i]?.interviewee_Email : null, // Client Email
          companyName: rawClientList[i]?.node_Name, // Office Name
          responsablePartyName: rawClientList[i]?.resp_Name, // respondent full name
          responsablePartyPrimaryPhoneNumber: responsablePartyPrimaryPhoneNumber,
          responsablePartyEmail: rawClientList[i]?.resp_Email?.includes("@") ? rawClientList[i]?.resp_Email : null,
          status: rawClientList[i]?.is_Text,
          callStatus: rawClientList[i]?.call_status,
          admittedDate: startedformattedDate,
          dischargeDate: endformattedDate,
          preferredMethod: rawClientList[i]?.sm_Type,
          responsablePhone1: rawClientList[i]?.resp_Phone1,
          responsablePhone2: rawClientList[i]?.resp_Phone2,
        });

      if (!statusTracker.includes(rawClientList[i]?.is_Text)) {
        statusFilter.push({
          text: rawClientList[i]?.is_Text,
          value: rawClientList[i]?.is_Text
        });
        statusTracker.push(rawClientList[i]?.is_Text);
      }

      if (!preferredMethodTracker.includes(rawClientList[i]?.sm_Type)) {
        preferredMethodFilter.push({
          text: rawClientList[i]?.sm_Type,
          value: rawClientList[i]?.sm_Type
        });
        preferredMethodTracker.push(rawClientList[i]?.sm_Type);
      }

      if ((startedformattedDate && !admittedDateTracker.includes(startedformattedDate))) {
        admittedDateFilter.push({
          text: startedformattedDate,
          value: startedformattedDate
        });
        admittedDateTracker.push(startedformattedDate);
      }

      if ((startedformattedDate && !dischargeDateTracker.includes(endformattedDate))) {
        dischargeDateFilter.push({
          text: endformattedDate,
          value: endformattedDate
        });
        dischargeDateTracker.push(endformattedDate);
      }

      if (!callStatusTracker.includes(rawClientList[i]?.call_status)) {
        callStatusFilter.push({
          text: rawClientList[i]?.call_status,
          value: rawClientList[i]?.call_status
        });
        callStatusTracker.push(rawClientList[i]?.call_status);
      }

      if ((rawClientList[i]?.interviewee_First && !firstNameTracker.includes(rawClientList[i]?.interviewee_First))) {
        firstNameFilter.push({
          text: rawClientList[i]?.interviewee_First,
          value: rawClientList[i]?.interviewee_First
        });
        firstNameTracker.push(rawClientList[i]?.interviewee_First);
      }

      if ((rawClientList[i]?.interviewee_Last && !lastNameTracker.includes(rawClientList[i]?.interviewee_Last))) {
        lastNameFilter.push({
          text: rawClientList[i]?.interviewee_Last,
          value: rawClientList[i]?.interviewee_Last
        });
        lastNameTracker.push(rawClientList[i]?.interviewee_Last);
      }

      if ((rawClientList[i]?.CompanyName && !companyNameTracker.includes(rawClientList[i]?.CompanyName))) {
        companyNameFilter.push({
          text: rawClientList[i]?.CompanyName,
          value: rawClientList[i]?.CompanyName
        });
        companyNameTracker.push(rawClientList[i]?.CompanyName);
      }
    }

    // render Prefiltered Array
    let resultsWithDefaultFilters = defaultColumnFilters?.setPreRenderFilteres ? await filterByAssignedPropertyArray(clientsResults, defaultColumnFilters?.status) : null;

    const clientsColumns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        editable: false,
        filters: [
          ...firstNameFilter
        ],
        onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        editable: false,
        filters: [
          ...lastNameFilter
        ],
        onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Primary Phone Number',
        dataIndex: 'primaryPhoneNumber',
        editable: false,
        onFilter: (value, record) => record.primaryPhoneNumber?.indexOf(value) === 0,
        sorter: (a, b) => a.primaryPhoneNumber.length - b.primaryPhoneNumber.length,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        editable: false,
        sorter: (a, b) => a.email.length - b.email.length,
      },
      {
        title: 'Preferred Method',
        dataIndex: 'preferredMethod',
        editable: false,
        filters: [
          ...preferredMethodFilter
        ],
        onFilter: (value, record) => record.preferredMethod?.indexOf(value) === 0,
        sorter: (a, b) => a.preferredMethod.length - b.preferredMethod.length,
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        editable: false,
        sorter: (a, b) => a.companyName.length - b.companyName.length,
      },
      {
        title: 'Responsable Party Name',
        dataIndex: 'responsablePartyName',
        editable: false,
        sorter: (a, b) => a.responsablePartyName.length - b.responsablePartyName.length,
      },
      {
        title: 'Responsable Party Phone',
        dataIndex: 'responsablePartyPrimaryPhoneNumber',
        editable: false,
        sorter: (a, b) => a.responsablePartyPrimaryPhoneNumber.length - b.responsablePartyPrimaryPhoneNumber.length,
      },
      {
        title: 'Responsable Party Email',
        dataIndex: 'responsablePartyEmail',
        editable: false,
        sorter: (a, b) => a.responsablePartyEmail.length - b.responsablePartyEmail.length,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        editable: false,
        filters: [
          ...statusFilter
        ],
        onFilter: (value, record) => record.status.startsWith(value),
        sorter: (a, b) => a.status.length - b.status.length,
        filterMode: 'menu',
        filterSearch: true,
        defaultFilteredValue: defaultColumnFilters?.status,
      },
      {
        title: 'Call Status',
        dataIndex: 'callStatus',
        editable: false,
        filters: [
          ...callStatusFilter
        ],
        filterMode: 'menu',
        filterSearch: true,
        onFilter: (value, record) => record.callStatus.startsWith(value),
        sorter: (a, b) => a.callStatus.length - b.callStatus.length,
      },
      {
        title: 'Admitted Date',
        dataIndex: 'admittedDate',
        editable: false,
        filters: [
          ...admittedDateFilter
        ],
        filterMode: 'menu',
        filterSearch: true,
        onFilter: (value, record) => record.admittedDate.startsWith(value),
        sorter: (a, b) => new Date(a.admittedDate) - new Date(b.admittedDate),
      },
      {
        title: 'Discharge Date',
        dataIndex: 'dischargeDate',
        editable: false,
        filters: [
          ...dischargeDateFilter
        ],
        filterMode: 'menu',
        filterSearch: true,
        onFilter: (value, record) => record.dischargeDate.startsWith(value),
        sorter: (a, b) => new Date(a.dischargeDate) - new Date(b.dischargeDate),
      }
    ];


    clientsListReportConfiguration = {
      clientsResults: clientsResults,
      clientsColumns: clientsColumns,
      clientResultsWithDefaultFilters: resultsWithDefaultFilters
    }

    console.log("Vantage clientsListReportConfiguration");
    console.log(clientsListReportConfiguration);

    return clientsListReportConfiguration;
  }
  return clientsListReportConfiguration;
}

export const handleSurveysConversion = async (companySyncSurveyResponses) => {
  let surveysReportConfiguration = {};
  if (companySyncSurveyResponses) {
    let allUnfilteredSurveysIds = await getAllUnfilteredSurveysIds(companySyncSurveyResponses);
    let uniqueUnfilteredSurveysIds = await getuniqueUnfilteredSurveysIds(allUnfilteredSurveysIds);
    let surveyResultsGroupedById = [];

    let npsLevelFilter = [];
    let npsLevelTracker = [];
    let npsScoreFilter = [];
    let npsScoreTracker = [];
    let dateFilter = [];
    let dateTracker = [];
    let companyNameFilter = [];
    let companyNameTracker = [];
    let surveyTitleFilter = [];
    let surveyTitleTracker = [];
    let firstNameFilter = [];
    let firstNameTracker = [];
    let lastNameFilter = [];
    let lastNameTracker = [];

    for (let i = 0; i < uniqueUnfilteredSurveysIds?.length; i++) {

      const filteredSurveys = companySyncSurveyResponses?.filter(obj => {
        return obj.SurveyResponseId === uniqueUnfilteredSurveysIds[i];
      });

      for (let j = 0; j < filteredSurveys?.length; j++) {
        if (filteredSurveys[j]?.Language_QuestionText?.includes("[provider name]")) {
          filteredSurveys[j].Language_QuestionText = replaceStubbedWithCompanyName(filteredSurveys[j]?.Language_QuestionText, filteredSurveys[j]?.CompanyName);
        }
      }

      let formattedDate = formatSurveyDate(filteredSurveys[0]?.StartedAt);

      surveyResultsGroupedById.push(
        {
          key: i,
          surveyId: uniqueUnfilteredSurveysIds[i],
          surveyDate: formattedDate,
          firstName: filteredSurveys[0].FirstName,
          lastName: filteredSurveys[0].LastName,
          npsScore: filteredSurveys[0].NpsScore,
          npsLevelId: filteredSurveys[0].NpsLevelId,
          surveyTitle: filteredSurveys[0].SurveyTitle,
          companyName: filteredSurveys[0].CompanyName,
          contactRelationship: filteredSurveys[0].ContactRelationId,
          allFurtherSurveyResults: filteredSurveys,
        });

      if ((formattedDate && !dateTracker.includes(formattedDate))) {
        dateFilter.push({
          text: formattedDate,
          value: formattedDate
        });
        dateTracker.push(formattedDate);
      }

      if ((filteredSurveys[0]?.FirstName && !firstNameTracker.includes(filteredSurveys[0].FirstName))) {
        firstNameFilter.push({
          text: filteredSurveys[0].FirstName,
          value: filteredSurveys[0].FirstName
        });
        firstNameTracker.push(filteredSurveys[0].FirstName);
      }

      if ((filteredSurveys[0]?.LastName && !lastNameTracker.includes(filteredSurveys[0].LastName))) {
        lastNameFilter.push({
          text: filteredSurveys[0].LastName,
          value: filteredSurveys[0].LastName
        });
        lastNameTracker.push(filteredSurveys[0].LastName);
      }

      if ((filteredSurveys[0]?.CompanyName && !companyNameTracker.includes(filteredSurveys[0].CompanyName))) {
        companyNameFilter.push({
          text: filteredSurveys[0].CompanyName,
          value: filteredSurveys[0].CompanyName
        });
        companyNameTracker.push(filteredSurveys[0].CompanyName);
      }

      if ((filteredSurveys[0]?.SurveyTitle && !surveyTitleTracker.includes(filteredSurveys[0].SurveyTitle))) {
        surveyTitleFilter.push({
          text: filteredSurveys[0].SurveyTitle,
          value: filteredSurveys[0].SurveyTitle
        });
        surveyTitleTracker.push(filteredSurveys[0].SurveyTitle);
      }

      if (!npsLevelTracker.includes(filteredSurveys[0].NpsLevelId)) {
        npsLevelFilter.push({
          text: filteredSurveys[0].NpsLevelId,
          value: filteredSurveys[0].NpsLevelId
        });
        npsLevelTracker.push(filteredSurveys[0].NpsLevelId);
      }

      if (!npsScoreTracker.includes(filteredSurveys[0].NpsScore)) {
        npsScoreFilter.push({
          text: filteredSurveys[0].NpsScore,
          value: filteredSurveys[0].NpsScore
        });
        npsScoreTracker.push(filteredSurveys[0].NpsScore);
      }

      if (!dateTracker.includes(formattedDate)) {
        dateFilter.push({
          text: formattedDate,
          value: formattedDate
        });
        dateTracker.push(formattedDate);
      }
    }

    const surveysColumns = [
      {
        title: 'Survey Date',
        dataIndex: 'surveyDate',
        width: '10%',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...dateFilter
        ],
        onFilter: (value, record) => record.surveyDate.startsWith(value),
        sorter: (a, b) => new Date(a.surveyDate) - new Date(b.surveyDate),
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        width: '15%',
        editable: false,
        filterSearch: true,
        filters: [
          ...companyNameFilter
        ],
        onFilter: (value, record) => record.companyName?.indexOf(value) === 0,
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Survey Title',
        dataIndex: 'surveyTitle',
        width: '15%',
        editable: false,
        filterSearch: true,
        filters: [
          ...surveyTitleFilter
        ],
        onFilter: (value, record) => record.surveyTitle?.indexOf(value) === 0,
        sorter: (a, b) => a.surveyTitle.localeCompare(b.surveyTitle),
        sortDirections: ["ascend", "descend"],
        
      },
      {
        title: 'Contact Relation',
        dataIndex: 'contactRelationship',
        width: '15%',
        editable: false,
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        width: '15%',
        filterSearch: true,
        editable: false,
        filters: [
          ...firstNameFilter
        ],
        onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        width: '15%',
        filterSearch: true,
        editable: false,
        filters: [
          ...lastNameFilter
        ],
        onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Nps Level',
        dataIndex: 'npsLevelId',
        width: '10%',
        editable: false,
        filters: [
          ...npsLevelFilter
        ],
        onFilter: (value, record) => record.npsLevelId?.indexOf(value) === 0,
        sorter: (a, b) => a.npsLevelId.length - b.npsLevelId.length,
        render: (npsLevelId) => {
          let color;
          if (npsLevelId?.toLowerCase() === 'promoter') {
            color = 'green';
          } else if (npsLevelId?.toLowerCase() === 'passive') {
            color = 'yellow';
          } else {
            color = 'volcano'
          }
          return (
            <Tag color={color} key={npsLevelId}>
              {npsLevelId?.toUpperCase()}
            </Tag>
          )
        },
      },
      {
        title: 'Nps Score',
        dataIndex: 'npsScore',
        width: '15%',
        editable: false,
        filters: [
          ...npsScoreFilter
        ],
        align: "center",
        onFilter: (value, record) => record.npsScore === value ? true : false,
        sorter: (a, b) => a.npsScore - b.npsScore,
        render: (npsScore) => {
          let color;
          if (npsScore >= 9) {
            color = 'green';
          } else if (npsScore <= 8 && npsScore > 6) {
            color = 'yellow';
          } else {
            color = 'volcano'
          }
          return (
            <Tag color={color} key={npsScore}>
              {npsScore}
            </Tag>
          )
        },

      }
    ];

    surveysReportConfiguration = {
      surveysReportResults: surveyResultsGroupedById,
      surveysReportColumns: surveysColumns
    }

    console.log(surveyResultsGroupedById)

    return surveysReportConfiguration;
  }
  return surveysReportConfiguration;
}

export const handleAllSurveysConversion = async (companySyncSurveyResponses) => {
  let allSurveysReportConfiguration = {};
  if (companySyncSurveyResponses) {
    // Shape Survey objects for display
    let allUnfilteredSurveysIds = await getAllUnfilteredSurveysIds(companySyncSurveyResponses);
    let uniqueUnfilteredSurveysIds = await getuniqueUnfilteredSurveysIds(allUnfilteredSurveysIds);

    let tagIdFilter = [];
    let tagIdTracker = [];
    let questionFilter = [];
    let questionTracker = [];
    let surveyIdFilter = [];
    let surveyIdTracker = [];
    let ratingFilter = [];
    let ratingTracker = [];
    let companyNameFilter = [];
    let companyNameTracker = [];
    let companyIdFilter = [];
    let companyIdTracker = [];
    let surveyResultsGroupedById = [];
    let firstNameFilter = [];
    let firstNameTracker = [];
    let lastNameFilter = [];
    let lastNameTracker = [];
    let dateFilter = [];
    let dateTracker = [];
    let surveyTitleFilter = [];
    let surveyTitleTracker = [];

    for (let i = 0; i < uniqueUnfilteredSurveysIds?.length; i++) {

      const filteredSurveys = companySyncSurveyResponses?.filter(obj => {
        return obj.SurveyResponseId === uniqueUnfilteredSurveysIds[i];
      });

      for (let j = 0; j < filteredSurveys?.length; j++) {

        let formattedDate = formatSurveyDate(filteredSurveys[0]?.StartedAt);

        if (filteredSurveys[j]?.Language_QuestionText?.includes("[provider name]")) {
          filteredSurveys[j].Language_QuestionText = replaceStubbedWithCompanyName(filteredSurveys[j]?.Language_QuestionText, filteredSurveys[j]?.CompanyName);
        }

        surveyResultsGroupedById.push(
          {
            surveyId: filteredSurveys[j]?.SurveyResponseId, // Interview Id
            surveyDate: formattedDate,// Interview Date
            firstName: filteredSurveys[j]?.FirstName, // First Name
            lastName: filteredSurveys[j]?.LastName, // Last Name
            surveyTitle: filteredSurveys[j]?.SurveyTitle,// Survey Title
            companyName: filteredSurveys[j]?.CompanyName, // Office Name
            companyId: filteredSurveys[j]?.CompanyId, // HCP Office Number
            contactRelationship: filteredSurveys[j]?.ContactRelationId,// Contact Relation
            question: filteredSurveys[j]?.Language_QuestionText, // Question
            tagId: filteredSurveys[j]?.TagId, // Tag id
            valueBoolean: filteredSurveys[j]?.ValueBoolean, // True/False
            rating: filteredSurveys[j]?.ValueNumeric,// Rating
            comment: filteredSurveys[j]?.Comment, // Comment                    
          });

        if ((filteredSurveys[j]?.SurveyTitle && !surveyTitleTracker.includes(filteredSurveys[j].SurveyTitle))) {
          surveyTitleFilter.push({
            text: filteredSurveys[j].SurveyTitle,
            value: filteredSurveys[j].SurveyTitle
          });
          surveyTitleTracker.push(filteredSurveys[j].SurveyTitle);
        }

        if ((formattedDate && !dateTracker.includes(formattedDate))) {
          dateFilter.push({
            text: formattedDate,
            value: formattedDate
          });
          dateTracker.push(formattedDate);
        }

        if ((filteredSurveys[j]?.FirstName && !firstNameTracker.includes(filteredSurveys[j].FirstName))) {
          firstNameFilter.push({
            text: filteredSurveys[j].FirstName,
            value: filteredSurveys[j].FirstName
          });
          firstNameTracker.push(filteredSurveys[j].FirstName);
        }

        if ((filteredSurveys[j]?.LastName && !lastNameTracker.includes(filteredSurveys[j].LastName))) {
          lastNameFilter.push({
            text: filteredSurveys[j].LastName,
            value: filteredSurveys[j].LastName
          });
          lastNameTracker.push(filteredSurveys[j].LastName);
        }

        if ((filteredSurveys[j]?.CompanyName && !companyNameTracker.includes(filteredSurveys[j].CompanyName))) {
          companyNameFilter.push({
            text: filteredSurveys[j].CompanyName,
            value: filteredSurveys[j].CompanyName
          });
          companyNameTracker.push(filteredSurveys[j].CompanyName);
        }

        if ((filteredSurveys[j]?.CompanyId && !companyIdTracker.includes(filteredSurveys[j].CompanyId))) {
          companyIdFilter.push({
            text: filteredSurveys[j].CompanyId,
            value: filteredSurveys[j].CompanyId
          });
          companyIdTracker.push(filteredSurveys[j].CompanyId);
        }

        if ((filteredSurveys[j]?.TagId && !tagIdTracker.includes(filteredSurveys[j].TagId))) {
          tagIdFilter.push({
            text: filteredSurveys[j].TagId,
            value: filteredSurveys[j].TagId
          });
          tagIdTracker.push(filteredSurveys[j].TagId);
        }

        if ((filteredSurveys[j].Language_QuestionText && !questionTracker.includes(filteredSurveys[j]?.Language_QuestionText))) {
          questionFilter.push({
            text: filteredSurveys[j]?.Language_QuestionText,
            value: filteredSurveys[j]?.Language_QuestionText
          });
          questionTracker.push(filteredSurveys[j]?.Language_QuestionText);
        }

        if (!surveyIdTracker.includes(filteredSurveys[j]?.SurveyResponseId)) {
          surveyIdFilter.push({
            text: filteredSurveys[j]?.SurveyResponseId,
            value: filteredSurveys[j]?.SurveyResponseId
          });
          surveyIdTracker.push(filteredSurveys[j]?.SurveyResponseId);
        }

        if (!ratingTracker.includes(Math.round(filteredSurveys[j]?.ValueNumeric))) {
          ratingFilter.push({
            text: Math.round(filteredSurveys[j]?.ValueNumeric),
            value: Math.round(filteredSurveys[j]?.ValueNumeric)
          });
          ratingTracker.push(Math.round(filteredSurveys[j]?.ValueNumeric));
        }
      }

    }

    // Add proper index for selection
    surveyResultsGroupedById?.forEach(function (elem, index) {
      elem.key = index;
    });

    const allSurveysColumns = [
      {
        title: 'Interview Id',
        dataIndex: 'surveyId',
        width: '15%',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...surveyIdFilter
        ],
        onFilter: (value, record) => record.surveyId?.indexOf(value) === 0,
        sorter: {
          compare: (a, b) => a.surveyId.length - b.surveyId.length,
          multiple: 3,
        }
      },
      {
        title: 'Contact Relation',
        dataIndex: 'contactRelationship',

        editable: false,
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        filterSearch: true,
        editable: false,
        filters: [
          ...firstNameFilter
        ],
        onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        filterSearch: true,
        editable: false,
        filters: [
          ...lastNameFilter
        ],
        onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Interview Date',
        dataIndex: 'surveyDate',
        editable: false,
        filters: [
          ...dateFilter
        ],
        filterMode: 'menu',
        filterSearch: true,
        onFilter: (value, record) => record.surveyDate.startsWith(value),
        sorter: (a, b) => new Date(a.surveyDate) - new Date(b.surveyDate),
      },
      {
        title: 'Survey Title',
        dataIndex: 'surveyTitle',
        editable: false,
        filterSearch: true,
        filters: [
          ...surveyTitleFilter
        ],
        onFilter: (value, record) => record.surveyTitle?.indexOf(value) === 0,
        sorter: (a, b) => a.surveyTitle.localeCompare(b.surveyTitle),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'HCP Office Number',
        dataIndex: 'companyId',

        editable: false,
        filterSearch: true,
        filters: [
          ...companyIdFilter
        ],
        onFilter: (value, record) => record.companyId === value ? true : false,
        sorter: (a, b) => a.companyId - b.companyId,
      },
      {
        title: 'Office Name',
        dataIndex: 'companyName',
        width: '15%',
        editable: false,
        filterSearch: true,
        filters: [
          ...companyNameFilter
        ],
        onFilter: (value, record) => record.companyName?.indexOf(value) === 0,
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Question',
        dataIndex: 'question',

        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...questionFilter
        ],
        onFilter: (value, record) => record.question?.indexOf(value) === 0,
      },
      {
        title: 'Tag Id',
        dataIndex: 'tagId',

        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...tagIdFilter
        ],
        onFilter: (value, record) => record.tagId?.indexOf(value) === 0,
        sorter: (a, b) => a.tagId?.length - b.tagId.length,
        render: (tagId) => {
          return (
            <span style={{ color: 'blue' }} key={tagId}>
              {tagId?.toUpperCase()}
            </span>
          )
        },
      },
      {
        title: 'True or False',
        dataIndex: 'valueBoolean',
        editable: false,
        render: (valueBoolean) => {
          let valueB = valueBoolean;
          if (valueB === true) {
            valueB = 'True';
          } else if (valueB === false) {
            valueB = 'False';
          } else {
            valueB = ''
          }
          return (
            <span>
              {valueB}
            </span>
          )
        },
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        align: "center",
        editable: false,
        filters: [
          ...ratingFilter
        ],
        onFilter: (value, record) => record.rating === value ? true : false,
        sorter: (a, b) => a.rating - b.rating,
        render: (rating) => {
          let color;
          if (rating) {
            if (rating >= 9) {
              color = 'green';
            } else if (rating <= 8 && rating > 6) {
              color = 'yellow';
            } else {
              color = 'volcano'
            }
            return (
              <Tag color={color} key={rating}>
                {rating}
              </Tag>
            )
          }
        },
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        editable: false,
      },
    ];

    allSurveysReportConfiguration = {
      allSurveysReportResults: surveyResultsGroupedById,
      allSurveysReportColumns: allSurveysColumns
    }

    return allSurveysReportConfiguration;
  }
  return allSurveysReportConfiguration;
}

export const handleSurveyResponseConversion = async (companySyncSurveyResponses, companyId, isCompanyGroup, groupId) => {
  let reportConfiguration = {};
  if (companySyncSurveyResponses) {
    let allUnfilteredSurveysIds = await getAllUnfilteredSurveysIds(companySyncSurveyResponses);
    let uniqueUnfilteredSurveysIds = await getuniqueUnfilteredSurveysIds(allUnfilteredSurveysIds);

    let surveyResultsGroupedById = [];
    let surveyResultsAllQuestions = [];

    let npsLevelFilter = [];
    let npsLevelTracker = [];
    let npsScoreFilter = [];
    let npsScoreTracker = [];
    let dateFilter = [];
    let dateTracker = [];
    let companyNameFilter = [];
    let companyNameTracker = [];
    let surveyTitleFilter = [];
    let surveyTitleTracker = [];
    let firstNameFilter = [];
    let firstNameTracker = [];
    let lastNameFilter = [];
    let lastNameTracker = [];
    let companyGroupNameFilter = [];
    let companyGroupNameTracker = [];

    let singleTagIdFilter = [];
    let singleTagIdTracker = [];
    let singleQuestionFilter = [];
    let singleQuestionTracker = [];
    let singleSurveyIdFilter = [];
    let singleSurveyIdTracker = [];
    let singleRatingFilter = [];
    let singleRatingTracker = [];
    let singleCompanyNameFilter = [];
    let singleCompanyNameTracker = [];
    let singleCompanyIdFilter = [];
    let singleCompanyIdTracker = [];
    let singleFirstNameFilter = [];
    let singleFirstNameTracker = [];
    let singleLastNameFilter = [];
    let singleLastNameTracker = [];
    let singleDateFilter = [];
    let singleDateTracker = [];
    let singleSurveyTitleFilter = [];
    let singleSurveyTitleTracker = [];
    let singleCompanyGroupNameFilter = [];
    let singleCompanyGroupNameTracker = [];

    for (let i = 0; i < uniqueUnfilteredSurveysIds?.length; i++) {

      const filteredSurveys = companySyncSurveyResponses?.filter(obj => {
        return obj.SurveyResponseId === uniqueUnfilteredSurveysIds[i];
      });

      let formattedDate = formatSurveyDate(filteredSurveys[0]?.StartedAt);

      for (let j = 0; j < filteredSurveys?.length; j++) {

        if (filteredSurveys[j]?.Language_QuestionText?.includes("[provider name]")) {
          filteredSurveys[j].Language_QuestionText = replaceStubbedWithCompanyName(filteredSurveys[j]?.Language_QuestionText, filteredSurveys[j]?.CompanyName);
        }

        surveyResultsAllQuestions.push(
          {
            surveyId: filteredSurveys[j]?.SurveyResponseId, // Interview Id
            surveyDate: formattedDate,// Interview Date
            firstName: filteredSurveys[j]?.FirstName, // First Name
            lastName: filteredSurveys[j]?.LastName, // Last Name
            surveyTitle: filteredSurveys[j]?.SurveyTitle,// Survey Title
            companyName: filteredSurveys[j]?.CompanyName, // Office Name
            companyId: filteredSurveys[j]?.CompanyId, // HCP Office Number
            contactRelationship: filteredSurveys[j]?.ContactRelationId,// Contact Relation
            question: filteredSurveys[j]?.Language_QuestionText, // Question
            tagId: filteredSurveys[j]?.TagId, // Tag id
            valueBoolean: filteredSurveys[j]?.ValueBoolean, // True/False
            rating: filteredSurveys[j]?.ValueNumeric,// Rating
            comment: filteredSurveys[j]?.Comment, // Comment,
            companyGroupName: filteredSurveys[j]?.CompanyGroupName
          });

        if ((filteredSurveys[j]?.SurveyTitle && !singleSurveyTitleTracker.includes(filteredSurveys[j].SurveyTitle))) {
          singleSurveyTitleFilter.push({
            text: filteredSurveys[j].SurveyTitle,
            value: filteredSurveys[j].SurveyTitle
          });
          singleSurveyTitleTracker.push(filteredSurveys[j].SurveyTitle);
        }

        if ((formattedDate && !singleDateTracker.includes(formattedDate))) {
          singleDateFilter.push({
            text: formattedDate,
            value: formattedDate
          });
          singleDateTracker.push(formattedDate);
        }

        if ((filteredSurveys[j]?.FirstName && !singleFirstNameTracker.includes(filteredSurveys[j].FirstName))) {
          singleFirstNameFilter.push({
            text: filteredSurveys[j].FirstName,
            value: filteredSurveys[j].FirstName
          });
          singleFirstNameTracker.push(filteredSurveys[j].FirstName);
        }

        if ((filteredSurveys[j]?.LastName && !singleLastNameTracker.includes(filteredSurveys[j].LastName))) {
          singleLastNameFilter.push({
            text: filteredSurveys[j].LastName,
            value: filteredSurveys[j].LastName
          });
          singleLastNameTracker.push(filteredSurveys[j].LastName);
        }

        if ((filteredSurveys[j]?.CompanyName && !singleCompanyNameTracker.includes(filteredSurveys[j].CompanyName))) {
          singleCompanyNameFilter.push({
            text: filteredSurveys[j].CompanyName,
            value: filteredSurveys[j].CompanyName
          });
          singleCompanyNameTracker.push(filteredSurveys[j].CompanyName);
        }

        if ((filteredSurveys[j]?.CompanyGroupName && !singleCompanyGroupNameTracker.includes(filteredSurveys[j].CompanyGroupName))) {
          singleCompanyGroupNameFilter.push({
            text: filteredSurveys[j].CompanyGroupName,
            value: filteredSurveys[j].CompanyGroupName
          });
          singleCompanyGroupNameTracker.push(filteredSurveys[j].CompanyGroupName);
        }

        if ((filteredSurveys[j]?.CompanyId && !singleCompanyIdTracker.includes(filteredSurveys[j].CompanyId))) {
          singleCompanyIdFilter.push({
            text: filteredSurveys[j].CompanyId,
            value: filteredSurveys[j].CompanyId
          });
          singleCompanyIdTracker.push(filteredSurveys[j].CompanyId);
        }

        if ((filteredSurveys[j]?.TagId && !singleTagIdTracker.includes(filteredSurveys[j].TagId))) {
          singleTagIdFilter.push({
            text: filteredSurveys[j].TagId,
            value: filteredSurveys[j].TagId
          });
          singleTagIdTracker.push(filteredSurveys[j].TagId);
        }

        if ((filteredSurveys[j].Language_QuestionText && !singleQuestionTracker.includes(filteredSurveys[j]?.Language_QuestionText))) {
          singleQuestionFilter.push({
            text: filteredSurveys[j]?.Language_QuestionText,
            value: filteredSurveys[j]?.Language_QuestionText
          });
          singleQuestionTracker.push(filteredSurveys[j]?.Language_QuestionText);
        }

        if (!singleSurveyIdTracker.includes(filteredSurveys[j]?.SurveyResponseId)) {
          singleSurveyIdFilter.push({
            text: filteredSurveys[j]?.SurveyResponseId,
            value: filteredSurveys[j]?.SurveyResponseId
          });
          singleSurveyIdTracker.push(filteredSurveys[j]?.SurveyResponseId);
        }

        if (!singleRatingTracker.includes(Math.round(filteredSurveys[j]?.ValueNumeric))) {
          singleRatingFilter.push({
            text: Math.round(filteredSurveys[j]?.ValueNumeric),
            value: Math.round(filteredSurveys[j]?.ValueNumeric)
          });
          singleRatingTracker.push(Math.round(filteredSurveys[j]?.ValueNumeric));
        }
      }

      surveyResultsGroupedById.push(
        {
          key: i,
          surveyId: uniqueUnfilteredSurveysIds[i],
          surveyDate: formattedDate,
          firstName: filteredSurveys[0]?.FirstName,
          lastName: filteredSurveys[0]?.LastName,
          npsScore: filteredSurveys[0]?.NpsScore,
          npsLevelId: filteredSurveys[0]?.NpsLevelId,
          surveyTitle: filteredSurveys[0]?.SurveyTitle,
          companyName: filteredSurveys[0]?.CompanyName,
          contactRelationship: filteredSurveys[0]?.ContactRelationId,
          companyGroupName: filteredSurveys[0]?.CompanyGroupName,
          allFurtherSurveyResults: filteredSurveys,
        });

      if ((formattedDate && !dateTracker.includes(formattedDate))) {
        dateFilter.push({
          text: formattedDate,
          value: formattedDate
        });
        dateTracker.push(formattedDate);
      }

      if ((filteredSurveys[0]?.FirstName && !firstNameTracker.includes(filteredSurveys[0].FirstName))) {
        firstNameFilter.push({
          text: filteredSurveys[0].FirstName,
          value: filteredSurveys[0].FirstName
        });
        firstNameTracker.push(filteredSurveys[0].FirstName);
      }

      if ((filteredSurveys[0]?.LastName && !lastNameTracker.includes(filteredSurveys[0].LastName))) {
        lastNameFilter.push({
          text: filteredSurveys[0].LastName,
          value: filteredSurveys[0].LastName
        });
        lastNameTracker.push(filteredSurveys[0].LastName);
      }

      if ((filteredSurveys[0]?.CompanyGroupName && !companyGroupNameTracker.includes(filteredSurveys[0]?.CompanyGroupName))) {
        companyGroupNameFilter.push({
          text: filteredSurveys[0].CompanyGroupName,
          value: filteredSurveys[0].CompanyGroupName
        });
        companyGroupNameTracker.push(filteredSurveys[0]?.CompanyGroupName);
      }

      if ((filteredSurveys[0]?.CompanyName && !companyNameTracker.includes(filteredSurveys[0].CompanyName))) {
        companyNameFilter.push({
          text: filteredSurveys[0].CompanyName,
          value: filteredSurveys[0].CompanyName
        });
        companyNameTracker.push(filteredSurveys[0].CompanyName);
      }

      if ((filteredSurveys[0]?.SurveyTitle && !surveyTitleTracker.includes(filteredSurveys[0].SurveyTitle))) {
        surveyTitleFilter.push({
          text: filteredSurveys[0].SurveyTitle,
          value: filteredSurveys[0].SurveyTitle
        });
        surveyTitleTracker.push(filteredSurveys[0].SurveyTitle);
      }

      if (!npsLevelTracker.includes(filteredSurveys[0].NpsLevelId)) {
        npsLevelFilter.push({
          text: filteredSurveys[0].NpsLevelId,
          value: filteredSurveys[0].NpsLevelId
        });
        npsLevelTracker.push(filteredSurveys[0].NpsLevelId);
      }

      if (!npsScoreTracker.includes(filteredSurveys[0].NpsScore)) {
        npsScoreFilter.push({
          text: filteredSurveys[0].NpsScore,
          value: filteredSurveys[0].NpsScore
        });
        npsScoreTracker.push(filteredSurveys[0].NpsScore);
      }

      if (!dateTracker.includes(formattedDate)) {
        dateFilter.push({
          text: formattedDate,
          value: formattedDate
        });
        dateTracker.push(formattedDate);
      }

    }

    // Add proper index for selection
    surveyResultsAllQuestions?.forEach(function (elem, index) {
      elem.key = index;
    });

    // Columns
    let allSurveysColumns = [];
    let surveysColumns = [];
    if(isCompanyGroup && groupId === companyConst.BAYADA_VANTAGE_COMPANY_GROUP){
      allSurveysColumns = [
        {
          title: 'Interview Id',
          dataIndex: 'surveyId',
          width: '5%',
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...singleSurveyIdFilter
          ],
          onFilter: (value, record) => record.surveyId?.indexOf(value) === 0,
          sorter: {
            compare: (a, b) => a.surveyId.length - b.surveyId.length,
            multiple: 3,
          }
        },
        {
          title: 'Contact Relation',
          dataIndex: 'contactRelationship',
          editable: false,
        },
        {
          title: 'First Name',
          dataIndex: 'firstName',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleFirstNameFilter
          ],
          onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
          sorter: (a, b) => a.firstName.localeCompare(b.firstName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Last Name',
          dataIndex: 'lastName',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleLastNameFilter
          ],
          onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
          sorter: (a, b) => a.lastName.localeCompare(b.lastName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Interview Date',
          dataIndex: 'surveyDate',
          editable: false,
          filters: [
            ...singleDateFilter
          ],
          filterMode: 'menu',
          filterSearch: true,
          onFilter: (value, record) => record.surveyDate.startsWith(value),
          sorter: (a, b) => new Date(a.surveyDate) - new Date(b.surveyDate),
        },
        {
          title: 'Survey Title',
          dataIndex: 'surveyTitle',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleSurveyTitleFilter
          ],
          onFilter: (value, record) => record.surveyTitle?.indexOf(value) === 0,
          sorter: (a, b) => a.surveyTitle.localeCompare(b.surveyTitle),
          sortDirections: ["ascend", "descend"],
        }, 
        {
          title: 'HCP Office Number',
          dataIndex: 'companyId',
          width: '8%',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleCompanyIdFilter
          ],
          onFilter: (value, record) => record.companyId === value ? true : false,
          sorter: (a, b) => a.companyId - b.companyId,
        },    
        {
          title: 'Office Name',
          dataIndex: 'companyName',
          width: '10%',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleCompanyNameFilter
          ],
          onFilter: (value, record) => record.companyName?.indexOf(value) === 0,
          sorter: (a, b) => a.companyName.localeCompare(b.companyName),
          sortDirections: ["ascend", "descend"],
        },       
        {
          title: 'Question',
          dataIndex: 'question',
  
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...singleQuestionFilter
          ],
          onFilter: (value, record) => record.question?.indexOf(value) === 0,
        },
        {
          title: 'Tag Id',
          dataIndex: 'tagId',
  
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...singleTagIdFilter
          ],
          onFilter: (value, record) => record.tagId?.indexOf(value) === 0,
          sorter: (a, b) => a.tagId?.length - b.tagId.length,
          render: (tagId) => {
            return (
              <span style={{ color: 'blue' }} key={tagId}>
                {tagId?.toUpperCase()}
              </span>
            )
          },
        },
        {
          title: 'True or False',
          dataIndex: 'valueBoolean',
          editable: false,
          render: (valueBoolean) => {
            let valueB = valueBoolean;
            if (valueB === true) {
              valueB = 'True';
            } else if (valueB === false) {
              valueB = 'False';
            } else {
              valueB = ''
            }
            return (
              <span>
                {valueB}
              </span>
            )
          },
        },
        {
          title: 'Rating',
          dataIndex: 'rating',
          align: "center",
          editable: false,
          filters: [
            ...singleRatingFilter
          ],
          onFilter: (value, record) => record.rating === value ? true : false,
          sorter: (a, b) => a.rating - b.rating,
          render: (rating) => {
            let color;
            if (rating) {
              if (rating >= 9) {
                color = 'green';
              } else if (rating <= 8 && rating > 6) {
                color = 'yellow';
              } else {
                color = 'volcano'
              }
              return (
                <Tag color={color} key={rating}>
                  {rating}
                </Tag>
              )
            }
          },
        },
        {
          title: 'Comment',
          dataIndex: 'comment',
          editable: false,
        },        
        {
          title: 'Division',
          dataIndex: 'companyGroupName',
          width: '10%',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleCompanyGroupNameFilter
          ],
          onFilter: (value, record) => record.companyGroupName?.indexOf(value) === 0,
          sorter: (a, b) => a.companyGroupName.localeCompare(b.companyGroupName),
          sortDirections: ["ascend", "descend"],
        }
      ];

      surveysColumns = [
        {
          title: 'Survey Date',
          dataIndex: 'surveyDate',
          width: '10%',
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...dateFilter
          ],
          onFilter: (value, record) => record.surveyDate.startsWith(value),
          sorter: (a, b) => new Date(a.surveyDate) - new Date(b.surveyDate),
        },
        {
          title: 'Division',
          dataIndex: 'companyGroupName',
          width: '15%',
          editable: false,
          filterSearch: true,
          filters: [
            ...companyGroupNameFilter
          ],
          onFilter: (value, record) => record.companyGroupName?.indexOf(value) === 0,
          sorter: (a, b) => a.companyGroupName.localeCompare(b.companyGroupName),
          sortDirections: ["ascend", "descend"],
          
        },
        {
          title: 'Company Name',
          dataIndex: 'companyName',
          width: '15%',
          editable: false,
          filterSearch: true,
          filters: [
            ...companyNameFilter
          ],
          onFilter: (value, record) => record.companyName?.indexOf(value) === 0,
          sorter: (a, b) => a.companyName.localeCompare(b.companyName),
          sortDirections: ["ascend", "descend"],
          
        },
        {
          title: 'Survey Title',
          dataIndex: 'surveyTitle',
          width: '15%',
          editable: false,
          filterSearch: true,
          filters: [
            ...surveyTitleFilter
          ],
          onFilter: (value, record) => record.surveyTitle?.indexOf(value) === 0,
          sorter: (a, b) => a.surveyTitle.localeCompare(b.surveyTitle),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Contact Relation',
          dataIndex: 'contactRelationship',
          width: '15%',
          editable: false,
        },
        {
          title: 'First Name',
          dataIndex: 'firstName',
          width: '15%',
          filterSearch: true,
          editable: false,
          filters: [
            ...firstNameFilter
          ],
          onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
          sorter: (a, b) => a.firstName.localeCompare(b.firstName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Last Name',
          dataIndex: 'lastName',
          width: '15%',
          filterSearch: true,
          editable: false,
          filters: [
            ...lastNameFilter
          ],
          onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
          sorter: (a, b) => a.lastName.localeCompare(b.lastName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Nps Level',
          dataIndex: 'npsLevelId',
          width: '10%',
          editable: false,
          filters: [
            ...npsLevelFilter
          ],
          onFilter: (value, record) => record.npsLevelId?.indexOf(value) === 0,
          sorter: (a, b) => a.npsLevelId.length - b.npsLevelId.length,
          render: (npsLevelId) => {
            let color;
            if (npsLevelId?.toLowerCase() === 'promoter') {
              color = 'green';
            } else if (npsLevelId?.toLowerCase() === 'passive') {
              color = 'yellow';
            } else {
              color = 'volcano'
            }
            return (
              <Tag color={color} key={npsLevelId}>
                {npsLevelId?.toUpperCase()}
              </Tag>
            )
          },
        },
        {
          title: 'Nps Score',
          dataIndex: 'npsScore',
          width: '15%',
          editable: false,
          filters: [
            ...npsScoreFilter
          ],
          align: "center",
          onFilter: (value, record) => record.npsScore === value ? true : false,
          sorter: (a, b) => a.npsScore - b.npsScore,
          render: (npsScore) => {
            let color;
            if (npsScore >= 9) {
              color = 'green';
            } else if (npsScore <= 8 && npsScore > 6) {
              color = 'yellow';
            } else {
              color = 'volcano'
            }
            return (
              <Tag color={color} key={npsScore}>
                {npsScore}
              </Tag>
            )
          },
  
        }
      ];
    }else{
      allSurveysColumns = [
        {
          title: 'Interview Id',
          dataIndex: 'surveyId',
          width: '10%',
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...singleSurveyIdFilter
          ],
          onFilter: (value, record) => record.surveyId?.indexOf(value) === 0,
          sorter: {
            compare: (a, b) => a.surveyId.length - b.surveyId.length,
            multiple: 3,
          }
        },
        {
          title: 'Contact Relation',
          dataIndex: 'contactRelationship',
  
          editable: false,
        },
        {
          title: 'First Name',
          dataIndex: 'firstName',
          filterSearch: true,
          editable: false,
          filters: [
            ...singleFirstNameFilter
          ],
          onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
          sorter: (a, b) => a.firstName.localeCompare(b.firstName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Last Name',
          dataIndex: 'lastName',
          filterSearch: true,
          editable: false,
          filters: [
            ...singleLastNameFilter
          ],
          onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
          sorter: (a, b) => a.lastName.localeCompare(b.lastName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Interview Date',
          dataIndex: 'surveyDate',
          editable: false,
          filters: [
            ...singleDateFilter
          ],
          filterMode: 'menu',
          filterSearch: true,
          onFilter: (value, record) => record.surveyDate.startsWith(value),
          sorter: (a, b) => new Date(a.surveyDate) - new Date(b.surveyDate),
        },
        {
          title: 'Survey Title',
          dataIndex: 'surveyTitle',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleSurveyTitleFilter
          ],
          onFilter: (value, record) => record.surveyTitle?.indexOf(value) === 0,
          sorter: (a, b) => a.surveyTitle.localeCompare(b.surveyTitle),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'HCP Office Number',
          dataIndex: 'companyId',
  
          editable: false,
          filterSearch: true,
          filters: [
            ...singleCompanyIdFilter
          ],
          onFilter: (value, record) => record.companyId === value ? true : false,
          sorter: (a, b) => a.companyId - b.companyId,
        },
        {
          title: 'Office Name',
          dataIndex: 'companyName',
          width: '15%',
          editable: false,
          filterSearch: true,
          filters: [
            ...singleCompanyNameFilter
          ],
          onFilter: (value, record) => record.companyName?.indexOf(value) === 0,
          sorter: (a, b) => a.companyName.localeCompare(b.companyName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Question',
          dataIndex: 'question',
  
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...singleQuestionFilter
          ],
          onFilter: (value, record) => record.question?.indexOf(value) === 0,
        },
        {
          title: 'Tag Id',
          dataIndex: 'tagId',
  
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...singleTagIdFilter
          ],
          onFilter: (value, record) => record.tagId?.indexOf(value) === 0,
          sorter: (a, b) => a.tagId?.length - b.tagId.length,
          render: (tagId) => {
            return (
              <span style={{ color: 'blue' }} key={tagId}>
                {tagId?.toUpperCase()}
              </span>
            )
          },
        },
        {
          title: 'True or False',
          dataIndex: 'valueBoolean',
          editable: false,
          render: (valueBoolean) => {
            let valueB = valueBoolean;
            if (valueB === true) {
              valueB = 'True';
            } else if (valueB === false) {
              valueB = 'False';
            } else {
              valueB = ''
            }
            return (
              <span>
                {valueB}
              </span>
            )
          },
        },
        {
          title: 'Rating',
          dataIndex: 'rating',
          align: "center",
          editable: false,
          filters: [
            ...singleRatingFilter
          ],
          onFilter: (value, record) => record.rating === value ? true : false,
          sorter: (a, b) => a.rating - b.rating,
          render: (rating) => {
            let color;
            if (rating) {
              if (rating >= 9) {
                color = 'green';
              } else if (rating <= 8 && rating > 6) {
                color = 'yellow';
              } else {
                color = 'volcano'
              }
              return (
                <Tag color={color} key={rating}>
                  {rating}
                </Tag>
              )
            }
          },
        },
        {
          title: 'Comment',
          dataIndex: 'comment',
          editable: false,
        },
      ];

      surveysColumns = [
        {
          title: 'Survey Date',
          dataIndex: 'surveyDate',
          width: '10%',
          editable: false,
          filterMode: 'menu',
          filterSearch: true,
          filters: [
            ...dateFilter
          ],
          onFilter: (value, record) => record.surveyDate.startsWith(value),
          sorter: (a, b) => new Date(a.surveyDate) - new Date(b.surveyDate),
        },
        {
          title: 'Company Name',
          dataIndex: 'companyName',
          width: '15%',
          editable: false,
          filterSearch: true,
          filters: [
            ...companyNameFilter
          ],
          onFilter: (value, record) => record.companyName?.indexOf(value) === 0,
          sorter: (a, b) => a.companyName.localeCompare(b.companyName),
          sortDirections: ["ascend", "descend"],
          
        },
        {
          title: 'Survey Title',
          dataIndex: 'surveyTitle',
          width: '15%',
          editable: false,
          filterSearch: true,
          filters: [
            ...surveyTitleFilter
          ],
          onFilter: (value, record) => record.surveyTitle?.indexOf(value) === 0,
          sorter: (a, b) => a.surveyTitle.localeCompare(b.surveyTitle),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Contact Relation',
          dataIndex: 'contactRelationship',
          width: '15%',
          editable: false,
        },
        {
          title: 'First Name',
          dataIndex: 'firstName',
          width: '15%',
          filterSearch: true,
          editable: false,
          filters: [
            ...firstNameFilter
          ],
          onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
          sorter: (a, b) => a.firstName.localeCompare(b.firstName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Last Name',
          dataIndex: 'lastName',
          width: '15%',
          filterSearch: true,
          editable: false,
          filters: [
            ...lastNameFilter
          ],
          onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
          sorter: (a, b) => a.lastName.localeCompare(b.lastName),
          sortDirections: ["ascend", "descend"],
        },
        {
          title: 'Nps Level',
          dataIndex: 'npsLevelId',
          width: '10%',
          editable: false,
          filters: [
            ...npsLevelFilter
          ],
          onFilter: (value, record) => record.npsLevelId?.indexOf(value) === 0,
          sorter: (a, b) => a.npsLevelId.length - b.npsLevelId.length,
          render: (npsLevelId) => {
            let color;
            if (npsLevelId?.toLowerCase() === 'promoter') {
              color = 'green';
            } else if (npsLevelId?.toLowerCase() === 'passive') {
              color = 'yellow';
            } else {
              color = 'volcano'
            }
            return (
              <Tag color={color} key={npsLevelId}>
                {npsLevelId?.toUpperCase()}
              </Tag>
            )
          },
        },
        {
          title: 'Nps Score',
          dataIndex: 'npsScore',
          width: '15%',
          editable: false,
          filters: [
            ...npsScoreFilter
          ],
          align: "center",
          onFilter: (value, record) => record.npsScore === value ? true : false,
          sorter: (a, b) => a.npsScore - b.npsScore,
          render: (npsScore) => {
            let color;
            if (npsScore >= 9) {
              color = 'green';
            } else if (npsScore <= 8 && npsScore > 6) {
              color = 'yellow';
            } else {
              color = 'volcano'
            }
            return (
              <Tag color={color} key={npsScore}>
                {npsScore}
              </Tag>
            )
          },
  
        }
      ];
    }
    
    reportConfiguration = {
      surveysReport: {
        data: surveyResultsGroupedById,
        columns: surveysColumns
      },
      allQuestionsReport: {
        data: surveyResultsAllQuestions,
        columns: allSurveysColumns
      }
    }

    return reportConfiguration;
  }
  return reportConfiguration;
}

export const transformRawCompanyGroupAndChildrenInSearchableResultSet = async (rawAgencyList) => {
  let items = [];
  items = rawAgencyList?.map((gp, i) =>
  (
    {
      key: i,
      label: gp.CompanyGroupName,
      value: `CompanyName:${gp.CompanyGroupName}|DwCompanyGroupId:${gp.CompanyGroupId}|CompanyIdVantage:${gp.CompanyId_Parent}|IsCompanyGroup:${(gp?.CompanyGroupTypeId === "Corporation" || gp?.CompanyGroupTypeId === "CustomRegion" || gp?.CompanyGroupTypeId === "CustomUnit") ? true : false}`,
      companyIdVantage: gp.CompanyId_Parent,
      IsCompanyGroup: (gp?.CompanyGroupTypeId === "Corporation" || gp?.CompanyGroupTypeId === "CustomRegion" || gp?.CompanyGroupTypeId === "CustomUnit") ? true : false,
      dwCompanyGroupId: gp.CompanyGroupId,
      icon: <ApartmentOutlined />,
      options: gp?.Company_CompanyGroup?.filter(e => e.Company !== null)?.sort((a, b) => {
        let fa = a.Company?.CompanyName?.toLowerCase(),
          fb = b.Company?.CompanyName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })?.map((c, ind) =>
      (
        {
          label: c.Company?.CompanyName,
          value: `CompanyName:${c.Company?.CompanyName}|DwCompanyGroupId:${c?.CompanyGroupId}|CompanyIdVantage:${c.Company?.CompanyId}|IsCompanyGroup:${(c?.CompanyGroupTypeId === "Corporation" || c?.CompanyGroupTypeId === "CustomRegion" || c?.CompanyGroupTypeId === "CustomUnit") ? true : false}`,
          companyIdVantage: c.Company?.CompanyId,
          IsCompanyGroup: (c?.CompanyGroupTypeId === "Corporation" || c?.CompanyGroupTypeId === "CustomRegion" || c?.CompanyGroupTypeId === "CustomUnit") ? true : false,
          dwCompanyGroupId: c?.CompanyGroupId,
          key: ind,
          disabled: false,
          icon: <HomeOutlined />
        }
      ))
    }
  ));

    const updatedData = items?.map(parent => {
      const options = parent.options || []; // Get the options array of the parent object
      options.unshift(parent); // Add the parent object as the first item in the options array
      return { ...parent, options }; // Return the updated parent object with the modified options array
    });
  
    return updatedData;
}

export const transformRawCompanyGroupAndChildrenForGoalsTable = async (rawAgencyList) => {
  let items = [];
  items = rawAgencyList?.map((gp, i) =>
  (
    {
      key: i,
      label: gp.CompanyGroupName,
      value: `CompanyName:${gp.CompanyGroupName}|DwCompanyGroupId:${gp.CompanyGroupId}|CompanyIdVantage:${gp.CompanyId_Parent}|IsCompanyGroup:${(gp?.CompanyGroupTypeId === "Corporation" || gp?.CompanyGroupTypeId === "CustomRegion" || gp?.CompanyGroupTypeId === "CustomUnit") ? true : false}`,
      companyIdVantage: gp.CompanyId_Parent,
      IsCompanyGroup: (gp?.CompanyGroupTypeId === "Corporation" || gp?.CompanyGroupTypeId === "CustomRegion" || gp?.CompanyGroupTypeId === "CustomUnit") ? true : false,
      dwCompanyGroupId: gp.CompanyGroupId,
      options: gp?.Company_CompanyGroup?.filter(e => e.Company !== null)?.sort((a, b) => {
        let fa = a.Company?.CompanyName?.toLowerCase(),
          fb = b.Company?.CompanyName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })?.map((c, ind) =>
      (
        {
          label: c.Company?.CompanyName,
          value: `CompanyName:${c.Company?.CompanyName}|DwCompanyGroupId:${c?.CompanyGroupId}|CompanyIdVantage:${c.Company?.CompanyId}|IsCompanyGroup:${(c?.CompanyGroupTypeId === "Corporation" || c?.CompanyGroupTypeId === "CustomRegion" || c?.CompanyGroupTypeId === "CustomUnit") ? true : false}`,
          companyIdVantage: c.Company?.CompanyId,
          IsCompanyGroup: (c?.CompanyGroupTypeId === "Corporation" || c?.CompanyGroupTypeId === "CustomRegion" || c?.CompanyGroupTypeId === "CustomUnit") ? true : false,
          dwCompanyGroupId: c?.CompanyGroupId,
          key: ind + 1000,
          disabled: false
        }
      ))
    }
  ));

  
  return items;
}

export const getGroupIdsAndOptionsString = async (data) => {
  let companyGroupIdsString = '';
  let companiesSyncIdsString = '';


  data?.forEach(item => {
    if (item?.IsCompanyGroup) {
      companyGroupIdsString += item.dwCompanyGroupId + ',';
    }

    if (item?.options && item?.options.length > 0) {
      item?.options.forEach(option => {
        companiesSyncIdsString += option?.companyIdVantage + ',';
      });
    }
  });

  // Remove trailing commas
  companyGroupIdsString = companyGroupIdsString?.slice(0, -1);
  companiesSyncIdsString = companiesSyncIdsString?.slice(0, -1);

  return { companyGroupIdsString, companiesSyncIdsString };
}

export const getLastPathInCurrentUrl = () => {
  const path = window.location.pathname.split('/');
  return path[path.length - 1];
}


export const getCompanyGroupNpsandOsat = async (allNpsandOsatData, allNpsandOsatGoals, activeCompanyWithCompanyGroupList) => {

  for (let i = 0; i < activeCompanyWithCompanyGroupList.length; i++) {
    const item = activeCompanyWithCompanyGroupList[i];
  
    if (item?.IsCompanyGroup) {
      const filteredGroup = allNpsandOsatData?.filter(obj => {
        return obj.CompanyId === item?.dwCompanyGroupId;
      });

      const { npsCompanyAverage, osatCompanyWeightedAverage } = await processOsatandNpsbyRangeDates(filteredGroup);
      item.npsAverage = npsCompanyAverage;
      item.osatWeightedAverage = osatCompanyWeightedAverage;

      const filteredGroupGoals = allNpsandOsatGoals?.filter(obj => {
        return obj.CompanyId === item?.dwCompanyGroupId;
      });

      const filteredGroupGoalsFirstItem = filteredGroupGoals[0];
      item.npsTarget = filteredGroupGoalsFirstItem?.NpsGoalStandard;
      item.npsBelowMin = filteredGroupGoalsFirstItem?.NpsGoalMinimum;
      item.osatTarget = filteredGroupGoalsFirstItem?.OsatGoalStandard;
      item.osatBelowMin = filteredGroupGoalsFirstItem?.OsatGoalMinimum;
  
      if (item?.options && item?.options.length > 0) {
        for (let j = 0; j < item.options.length; j++) {
          const option = item.options[j];
  
          const filteredSubgroup = allNpsandOsatData?.filter(obj => {
            return obj.CompanyId === option?.companyIdVantage;
          });
  
          const { npsCompanyAverage, osatCompanyWeightedAverage } = await processOsatandNpsbyRangeDates(filteredSubgroup);
          option.npsAverage = npsCompanyAverage;
          option.osatWeightedAverage = osatCompanyWeightedAverage;

          const filteredSubgroupGoal = allNpsandOsatGoals?.filter(obj => {
            return obj.CompanyId === option?.companyIdVantage;
          });

          const filteredSubgroupGoalFirstItem = filteredSubgroupGoal[0];
          option.npsTarget = filteredSubgroupGoalFirstItem?.NpsGoalStandard;
          option.npsBelowMin = filteredSubgroupGoalFirstItem?.NpsGoalMinimum;
          option.osatTarget = filteredSubgroupGoalFirstItem?.OsatGoalStandard;
          option.osatBelowMin = filteredSubgroupGoalFirstItem?.OsatGoalMinimum;

        }
      }
    }
  }

  
  return activeCompanyWithCompanyGroupList;

}

export const propagatetUpdateNPSandOSATPropertyToChildrenOfCompanyGroupByKeyForGoalsForm = (data, keyToSearch, formValues, activeRequestedReport) => {
  for (let i = 0; i < data.length + 1; i++) {
      if (data[i]?.key === keyToSearch) {
        // Update the property if the key matches
        if(activeRequestedReport === companyConst.NPS){
          data[i].npsTarget = Number(formValues?.npsTarget);
          data[i].npsBelowMin = Number(formValues?.npsBelowMin);

          if(formValues?.propagateToChildren && data[i]?.children && data[i]?.children?.length > 0){
            // Mass updated all children under parent
            data[i]?.children?.forEach(keyToSearch => {
              keyToSearch.npsTarget = Number(formValues?.npsTarget);
              keyToSearch.npsBelowMin = Number(formValues?.npsBelowMin);
            });
          }

          return data;
          
        }else if (activeRequestedReport === companyConst.OSAT){
          data[i].osatTarget = Number(formValues?.osatTarget);
          data[i].osatBelowMin = Number(formValues?.osatBelowMin);

          if(formValues?.propagateToChildren && data[i]?.children && data[i]?.children?.length > 0){
            // Mass updated all children under parent
            data[i]?.children?.forEach(keyToSearch => {
              keyToSearch.osatTarget = Number(formValues?.osatTarget);
              keyToSearch.osatBelowMin = Number(formValues?.osatBelowMin);
            });
          }

          return data;
        }
      } 
    }

}

export const updateNPSandOSATPropertyByKeyForGoalsForm = (data, keyToSearch, formValues, activeRequestedReport) => {
  for (let i = 0; i < data.length + 1; i++) {
      if (data[i]?.key === keyToSearch) {
        // Update the property if the key matches
        if(activeRequestedReport === companyConst.NPS){
          data[i].npsTarget = Number(formValues?.npsTarget);
          data[i].npsBelowMin = Number(formValues?.npsBelowMin);
          break;
        }else if (activeRequestedReport === companyConst.OSAT){
          data[i].osatTarget = Number(formValues?.osatTarget);
          data[i].osatBelowMin = Number(formValues?.osatBelowMin);
          break;
        }
      } else if (data[i]?.children && Array.isArray(data[i]?.children)) {
        updateNPSandOSATPropertyByKeyForGoalsForm(data[i]?.children, keyToSearch, formValues, activeRequestedReport);
      }
    }

    return data;
}

export const createGoalsObjectForDatabaseSubmittion = (selectedRecord, formValues, pocActiveTab, contactId) => {
    let goal = {
      ContactRelationId: contactId
    }

    if(selectedRecord?.isCompanyGroup){
      goal.CompanyGroupId = selectedRecord?.dwCompanyGroupId;
    }else{
      goal.CompanyId = selectedRecord?.hcpOfficeNumber;
    }

    if(pocActiveTab === companyConst.NPS){
      goal.NpsGoalStandard = Number(formValues?.npsTarget);
      goal.NpsGoalMinimum = Number(formValues?.npsBelowMin);

    }else if (pocActiveTab === companyConst.OSAT){
      goal.OsatGoalStandard = Number(formValues?.osatTarget);
      goal.OsatGoalMinimum = Number(formValues?.osatBelowMin);

    }

  return goal;
}

export const processOsatandNpsbyRangeDates = async(companyRangedData) => {

  // NPS Company
let npsCompanyAverage = 0;
let npsCompanyPromotersTotal = 0;
let npsCompanyDetractorsTotal = 0;
let npsCompanyTotalSum = 0;

// OSAT Company
let osatCompanyWeightedAverage = 0;
let osatCompanyTotal = 0;
let osatCompanyAverage = 0;


const cdd = companyRangedData;
for (let index = 0; index < cdd?.length; index++) {
    npsCompanyAverage += cdd[index]?.CompanyNpsScore;
    npsCompanyPromotersTotal += cdd[index]?.CompanyNpsPromoterCount;
    npsCompanyDetractorsTotal += cdd[index]?.CompanyNpsDetractorCount;
    npsCompanyTotalSum += cdd[index]?.CompanyNpsTotalCount;
    osatCompanyTotal += cdd[index]?.CompanyOverallSatisfactionTotalCount;
    osatCompanyWeightedAverage += (cdd[index]?.CompanyOverallSatisfactionScore * cdd[index]?.CompanyOverallSatisfactionTotalCount);
}

  //   NPS GENERAL CALCULATION
  const promoterPercent = npsCompanyPromotersTotal / npsCompanyTotalSum;
  const detractorPercent = npsCompanyDetractorsTotal / npsCompanyTotalSum;

  npsCompanyAverage = (promoterPercent - detractorPercent) * 100;
  npsCompanyAverage = handlePercentageIfNaN((Math.round((npsCompanyAverage + Number.EPSILON) * 10) / 10));


  // OSAT GENERAL CALCULATION COMPANY GROUP
  osatCompanyWeightedAverage = osatCompanyWeightedAverage / osatCompanyTotal;
  osatCompanyWeightedAverage = handlePercentageIfNaN(Math.round((osatCompanyWeightedAverage + Number.EPSILON) * 100) / 100);

  osatCompanyAverage = osatCompanyAverage / cdd?.length;
  osatCompanyAverage = handlePercentageIfNaN(Math.round((osatCompanyAverage + Number.EPSILON) * 100) / 100);

  return {
    npsCompanyAverage,
    osatCompanyWeightedAverage
  }

}

export const handleCompanyCompanyGroupGoalsReport = async(companyCompanyGroup) => {
  let companyCompanyGroupResults = [];
  let hcpOfficeNumberFilter = [];
  let hcpOfficeNumberTracker = [];
  let hcpOfficeNameFilter = [];
  let hcpOfficeNameTracker = [];

  if(companyCompanyGroup){
    let globalIndex = 1;
    for (let i = 0; i < companyCompanyGroup?.length; i++) {
      const currentItem = companyCompanyGroup[i];      
      const resultItem = {
        key: globalIndex++,
        internalKey:  i + 1,
        name: currentItem.label,
        hcpOfficeNumber: currentItem.companyIdVantage,
        numOfAgenciesUnder: (currentItem.options?.length > 0) ? currentItem.options?.length : 0,
        npsScore: currentItem?.npsAverage,
        osatScore: currentItem?.osatWeightedAverage,
        osatTarget: currentItem?.osatTarget,
        npsTarget: currentItem?.npsTarget,
        npsBelowMin: currentItem?.npsBelowMin,
        osatBelowMin: currentItem?.osatBelowMin,
        isCompanyGroup: currentItem?.IsCompanyGroup,
        dwCompanyGroupId: currentItem?.dwCompanyGroupId
      };
    
      if (currentItem.options && currentItem.options.length > 0) {          
          resultItem.children = currentItem.options.map((option, index) => ({
            key: globalIndex++,
            internalKey: i + 1 + index + 1,
            name: option.label,
            hcpOfficeNumber: option.companyIdVantage,
            npsScore: option.npsAverage,
            osatScore: option?.osatWeightedAverage,
            osatTarget: option?.osatTarget,
            npsTarget: option?.npsTarget,
            npsBelowMin: option?.npsBelowMin,
            osatBelowMin: option?.osatBelowMin,
            isCompanyGroup: option?.IsCompanyGroup
          }));
      }
    
      companyCompanyGroupResults.push(resultItem);

      if (!hcpOfficeNumberTracker.includes(currentItem.companyIdVantage)) {
        hcpOfficeNumberFilter.push({
          text: currentItem.companyIdVantage,
          value: currentItem.companyIdVantage
        });
        hcpOfficeNumberTracker.push(currentItem.companyIdVantage);
      }

      if (!hcpOfficeNameTracker.includes(currentItem.label)) {
        hcpOfficeNameFilter.push({
          text: currentItem.label,
          value: currentItem.label
        });
        hcpOfficeNameTracker.push(currentItem.label);
      }
    }

    const npsGoalsColumns = [
      {
        title: 'Num. of Agencies',
        dataIndex: 'numOfAgenciesUnder',
        width: '5%',        
        editable: false,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.numOfAgenciesUnder - b.numOfAgenciesUnder,
      },
      {
        title: 'Office Name',
        dataIndex: 'name',        
        editable: false,
        filterSearch: true,
        filters: [
          ...hcpOfficeNameFilter
        ],
        onFilter: (value, record) => record.name?.indexOf(value) === 0,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Hcp Office Number',
        dataIndex: 'hcpOfficeNumber',                
        editable: false,
        filters: [
          ...hcpOfficeNumberFilter
        ],
        onFilter: (value, record) => record.hcpOfficeNumber === value ? true : false,
        sorter: (a, b) => a.hcpOfficeNumber - b.hcpOfficeNumber,
        sortDirections: ["ascend", "descend"]
      },      
      {
        title: 'NPS Score',
        dataIndex: 'npsScore',
        editable: false,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.npsScore - b.npsScore,
      },
      {
        title: 'Nps Target',
        dataIndex: 'npsTarget',
        editable: true,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.npsTarget - b.npsTarget,
      },
      {
        title: 'Nps Below Min',
        dataIndex: 'npsBelowMin',
        editable: true,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.npsBelowMin - b.npsBelowMin,
      },
    ];

    const osatGoalsColumns = [
      {
        title: 'Num. of Agencies',
        dataIndex: 'numOfAgenciesUnder',
        width: '5%',        
        editable: false,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.numOfAgenciesUnder - b.numOfAgenciesUnder,
      },
      {
        title: 'Office Name',
        dataIndex: 'name',        
        editable: false,
        filters: [
          ...hcpOfficeNameFilter
        ],
        onFilter: (value, record) => record.name?.indexOf(value) === 0,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Hcp Office Number',
        dataIndex: 'hcpOfficeNumber',                
        editable: false,
        filters: [
          ...hcpOfficeNumberFilter
        ],
        onFilter: (value, record) => record.hcpOfficeNumber === value ? true : false,
        sorter: (a, b) => a.hcpOfficeNumber - b.hcpOfficeNumber,
        sortDirections: ["ascend", "descend"]
      },      
      {
        title: 'OSAT Score',
        dataIndex: 'osatScore',
        editable: false,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.osatScore - b.osatScore,
      },
      {
        title: 'OSAT Target',
        dataIndex: 'osatTarget',
        editable: true,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.osatTarget - b.osatTarget,
      },
      {
        title: 'OSAT Below Min',
        dataIndex: 'osatBelowMin',
        editable: true,
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.osatBelowMin - b.osatBelowMin,
      },
    ];

    return {
      goalsData: companyCompanyGroupResults,
      npsGoalsColumns: npsGoalsColumns,
      osatGoalsColumns: osatGoalsColumns
    };

  }
}

export const handleSurveysForCEXReport = async (companySyncResponses) => {
  let cexResults = [];
  if (companySyncResponses) {
    let surveyResponseIdFilter = [];
    let surveyResponseIdTracker = [];
    let firstNameFilter = [];
    let firstNameTracker = [];
    let lastNameFilter = [];
    let lastNameTracker = [];
    let startedAtFilter = [];
    let startedAtTracker = [];
    let companyId_BayadaFilter = [];
    let companyId_BayadaTracker = [];
    let companyNameFilter = [];
    let companyNameTracker = [];
    let companyGroupNameFilter = [];
    let companyGroupNameTracker = [];
    let valueNumericFilter = [];
    let valueNumericTracker = [];
    let surveyTypeFilter = [];
    let surveyTypeTracker = [];
    let isAnonymousFilter = [];
    let isAnonymousTracker = [];
    let companyIdFilter = [];
    let companyIdTracker = [];
    let tagIdFilter = [];
    let tagIdTracker = [];
    let externalContactIdFilter = [];
    let externalContactIdTracker = [];
    let npsLevelFilter = [];
    let npsLevelTracker = [];

    for (let i = 0; i < companySyncResponses?.length; i++) {

      let formattedDate = formatSurveyDate(companySyncResponses[i]?.StartedAt);
      let isAnonymous = (companySyncResponses[i]?.IsAnonymous) ? 'True' : 'False';
      let castedSurveyType = companySyncResponses[i]?.SurveyType === "Concluded" ? "Discharged" : "Active";

      cexResults.push(
        {
          key: i,
          interviewId: companySyncResponses[i]?.SurveyResponseId,
          hcpClientId: companySyncResponses[i]?.ExternalContactId,
          firstName: companySyncResponses[i]?.FirstName,
          lastName: companySyncResponses[i]?.LastName,
          interviewDate: formattedDate,
          officeNumber: companySyncResponses[i]?.CompanyId_Bayada,
          officeName: companySyncResponses[i]?.CompanyName,
          practice: companySyncResponses[i]?.CompanyGroupName,
          npsRating: companySyncResponses[i]?.ValueNumeric,
          surveyType: castedSurveyType,
          anonymous: isAnonymous,
          hcpOfficeNumber: companySyncResponses[i]?.CompanyId,
          tagId: companySyncResponses[i]?.TagId,
          npsLevelId: companySyncResponses[i]?.NpsLevelId
        });

      if ((companySyncResponses[i]?.SurveyResponseId && !surveyResponseIdTracker.includes(companySyncResponses[i].SurveyResponseId))) {
        surveyResponseIdFilter.push({
          text: companySyncResponses[i].SurveyResponseId,
          value: companySyncResponses[i].SurveyResponseId
        });
        surveyResponseIdTracker.push(companySyncResponses[i].SurveyResponseId);
      }

      if ((companySyncResponses[i]?.ExternalContactId && !externalContactIdTracker.includes(companySyncResponses[i].ExternalContactId))) {
        externalContactIdFilter.push({
          text: companySyncResponses[i].ExternalContactId,
          value: companySyncResponses[i].ExternalContactId
        });
        externalContactIdTracker.push(companySyncResponses[i].CompanyId);
      }

      if ((companySyncResponses[i]?.CompanyId && !companyIdTracker.includes(companySyncResponses[i].CompanyId))) {
        companyIdFilter.push({
          text: companySyncResponses[i].CompanyId,
          value: companySyncResponses[i].CompanyId
        });
        companyIdTracker.push(companySyncResponses[i].CompanyId);
      }

      if ((companySyncResponses[i]?.FirstName && !firstNameTracker.includes(companySyncResponses[i].FirstName))) {
        firstNameFilter.push({
          text: companySyncResponses[i].FirstName,
          value: companySyncResponses[i].FirstName
        });
        firstNameTracker.push(companySyncResponses[i].FirstName);
      }

      if ((companySyncResponses[i]?.LastName && !lastNameTracker.includes(companySyncResponses[i].LastName))) {
        lastNameFilter.push({
          text: companySyncResponses[i].LastName,
          value: companySyncResponses[i].LastName
        });
        lastNameTracker.push(companySyncResponses[i].LastName);
      }

      if ((formattedDate && !startedAtTracker.includes(formattedDate))) {
        startedAtFilter.push({
          text: formattedDate,
          value: formattedDate
        });
        startedAtTracker.push(formattedDate);
      }

      if ((companySyncResponses[i]?.CompanyId_Bayada && !companyId_BayadaTracker.includes(companySyncResponses[i].CompanyId_Bayada))) {
        companyId_BayadaFilter.push({
          text: companySyncResponses[i].CompanyId_Bayada,
          value: companySyncResponses[i].CompanyId_Bayada
        });
        companyId_BayadaTracker.push(companySyncResponses[i].CompanyId_Bayada);
      }

      if (!companyGroupNameTracker.includes(companySyncResponses[i].CompanyGroupName)) {
        companyGroupNameFilter.push({
          text: companySyncResponses[i].CompanyGroupName,
          value: companySyncResponses[i].CompanyGroupName
        });
        companyGroupNameTracker.push(companySyncResponses[i].CompanyGroupName);
      }

      if (!companyNameTracker.includes(companySyncResponses[i].CompanyName)) {
        companyNameFilter.push({
          text: companySyncResponses[i].CompanyName,
          value: companySyncResponses[i].CompanyName
        });
        companyNameTracker.push(companySyncResponses[i].CompanyName);
      }

      if (!valueNumericTracker.includes(companySyncResponses[i].ValueNumeric)) {
        valueNumericFilter.push({
          text: companySyncResponses[i].ValueNumeric,
          value: companySyncResponses[i].ValueNumeric
        });
        valueNumericTracker.push(companySyncResponses[i].ValueNumeric);
      }

      if (!surveyTypeTracker.includes(castedSurveyType)) {
        surveyTypeFilter.push({
          text: castedSurveyType,
          value: castedSurveyType
        });
        surveyTypeTracker.push(castedSurveyType);
      }

      if (!isAnonymousTracker.includes(isAnonymous)) {
        isAnonymousFilter.push({
          text: isAnonymous,
          value: isAnonymous
        });
        isAnonymousTracker.push(isAnonymous);
      }

      if (!tagIdTracker.includes(companySyncResponses[i].TagId)) {
        tagIdFilter.push({
          text: companySyncResponses[i].TagId,
          value: companySyncResponses[i].TagId
        });
        tagIdTracker.push(companySyncResponses[i].TagId);
      }

      if ((companySyncResponses[i]?.NpsLevelId && !npsLevelTracker.includes(companySyncResponses[i].NpsLevelId))) {
        npsLevelFilter.push({
          text: companySyncResponses[i].NpsLevelId,
          value: companySyncResponses[i].NpsLevelId
        });
        npsLevelTracker.push(companySyncResponses[i].NpsLevelId);
      }

    }

    const cexColumns = [
      {
        title: 'Interview ID',
        dataIndex: 'interviewId',
        editable: false,
        filterSearch: true,
        filters: [
          ...surveyResponseIdFilter
        ],
        onFilter: (value, record) => record.interviewId?.indexOf(value) === 0,
        sorter: (a, b) => a.interviewId.length - b.interviewId.length,

      },
      {
        title: 'HCP Client ID',
        dataIndex: 'hcpClientId',
        editable: false,
        filterSearch: true,
        filters: [
          ...externalContactIdFilter
        ],
        onFilter: (value, record) => record.hcpClientId?.indexOf(value) === 0,
        sorter: (a, b) => a.hcpClientId.length - b.hcpClientId.length,
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        editable: false,
        filterSearch: true,
        filters: [
          ...firstNameFilter
        ],
        onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        editable: false,
        filterSearch: true,
        filters: [
          ...lastNameFilter
        ],
        onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Interview Date',
        dataIndex: 'interviewDate',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...startedAtFilter
        ],
        onFilter: (value, record) => record.interviewDate.startsWith(value),
        sorter: (a, b) => new Date(a.interviewDate) - new Date(b.interviewDate),
      },
      {
        title: 'Office Number',
        dataIndex: 'officeNumber',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...companyId_BayadaFilter
        ],
        onFilter: (value, record) => record.officeNumber === value ? true : false,
        sorter: (a, b) => a.officeNumber - b.officeNumber,
      },
      {
        title: 'Office Name',
        dataIndex: 'officeName',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...companyId_BayadaFilter
        ],
        onFilter: (value, record) => record.officeName?.indexOf(value) === 0,
        sorter: (a, b) => a.officeName.localeCompare(b.officeName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Practice',
        dataIndex: 'practice',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...companyGroupNameFilter
        ],
        onFilter: (value, record) => record.practice?.indexOf(value) === 0,
        sorter: (a, b) => a.practice.localeCompare(b.practice),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'NPS Rating',
        dataIndex: 'npsRating',
        editable: false,
        filters: [
          ...valueNumericFilter
        ],
        align: "center",
        onFilter: (value, record) => record.npsRating === value ? true : false,
        sorter: (a, b) => a.npsRating - b.npsRating,
        render: (npsRating) => {
          let color;
          if (npsRating >= 9) {
            color = 'green';
          } else if (npsRating <= 8 && npsRating > 6) {
            color = 'yellow';
          } else {
            color = 'volcano'
          }
          return (
            <Tag color={color} key={npsRating}>
              {npsRating}
            </Tag>
          )
        }
      }, 
      {
        title: 'Survey Type',
        dataIndex: 'surveyType',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...surveyTypeFilter
        ],
        onFilter: (value, record) => record.surveyType?.indexOf(value) === 0,
        sorter: (a, b) => a.surveyType.localeCompare(b.surveyType),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Anonymous',
        dataIndex: 'anonymous',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...isAnonymousFilter
        ],
        onFilter: (value, record) => record.anonymous?.indexOf(value) === 0,
        sorter: (a, b) => a.anonymous.length - b.anonymous.length
      },
      {
        title: 'HCP Office Number',
        dataIndex: 'hcpOfficeNumber',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...companyIdFilter
        ],
        onFilter: (value, record) => record.hcpOfficeNumber?.indexOf(value) === 0,
        sorter: (a, b) => a.hcpOfficeNumber.length - b.hcpOfficeNumber.length
      },
      {
        title: 'Tag ID',
        dataIndex: 'tagId',
        editable: false,
      }, 
      {
        title: 'Nps Level',
        dataIndex: 'npsLevelId',
        width: '10%',
        editable: false,
        filters: [
          ...npsLevelFilter
        ],
        onFilter: (value, record) => record.npsLevelId?.indexOf(value) === 0,
        sorter: (a, b) => a.npsLevelId.length - b.npsLevelId.length,
        render: (npsLevelId) => {
          let color;
          if (npsLevelId?.toLowerCase() === 'promoter') {
            color = 'green';
          } else if (npsLevelId?.toLowerCase() === 'passive') {
            color = 'yellow';
          } else {
            color = 'volcano'
          }

          return (
            <Tag color={color} key={npsLevelId}>
              {npsLevelId?.toUpperCase()}
            </Tag>
          )
        },
      },
    ];


    return {
      data: cexResults,
      columns: cexColumns
    };

  }
}

export const handleSurveysForDncReport = async (dncResponses) => {
  let cexResults = [];

  if (dncResponses) {

    let intervieweeIdFilter = [];
    let intervieweeIdTracker = [];
    let companyIdFilter = [];
    let companyIdTracker = [];
    let companyNameFilter = [];
    let companyNameTracker = [];
    let firstNameFilter = [];
    let firstNameTracker = [];
    let lastNameFilter = [];
    let lastNameTracker = [];
    let interviewStatusIdFilter = [];
    let interviewStatusIdTracker = [];
    let textStatusFilter = [];
    let textStatusTracker = [];
    let companyGroupIdFilter = [];
    let companyGroupIdTracker = [];
    let companyGroupNameFilter = [];
    let companyGroupNameTracker = [];
    let regionIdFilter = [];
    let regionIdTracker = [];
    let regionNameFilter = [];
    let regionNameTracker = [];
    let franchiseIdFilter = [];
    let franchiseIdTracker = [];
    let officeGeographicalStateFilter = [];
    let officeGeographicalStateTracker = [];
    let intervieweeDNCFilter = [];
    let intervieweeDNCTracker = [];
    let startedAtFilter = [];
    let startedAtTracker = [];
    let endDateFilter = [];
    let endDateTracker = [];


    for (let i = 0; i < dncResponses?.length; i++) {

      let formattedBeginDate = formatSurveyDate(dncResponses[i]?.IntervieweeBeginDate);
      let formattedEndDate = formatSurveyDate(dncResponses[i]?.IntervieweeEndDate);

      cexResults.push(
        {
          key: i,
          interviewId: dncResponses[i]?.IntervieweeId,
          hcpOfficeNumber: dncResponses[i]?.OfficeId,
          officeName: dncResponses[i]?.OfficeName,
          firstName: dncResponses[i]?.FirstName,
          lastName: dncResponses[i]?.LastName,
          interviewStatusId: dncResponses[i]?.InterviewStatusId,
          textStatus: dncResponses[i]?.TextStatus,
          companyGroupId: dncResponses[i]?.CorporationId,
          companyGroupName: dncResponses[i]?.CorporateName,
          regionId: dncResponses[i]?.RegionId,
          regionName: dncResponses[i]?.RegionName,
          franchiseId: dncResponses[i]?.FranchiseId,
          officeGeographicalState: dncResponses[i]?.OfficeGeographicalState,
          intervieweeDNC: dncResponses[i]?.IntervieweeDNC,
          intervieweeBeginDate: formattedBeginDate,
          intervieweeEndDate: formattedEndDate
        });

      if ((dncResponses[i]?.IntervieweeId && !intervieweeIdTracker.includes(dncResponses[i].IntervieweeId))) {
        intervieweeIdFilter.push({
          text: dncResponses[i].IntervieweeId,
          value: dncResponses[i].IntervieweeId
        });
        intervieweeIdTracker.push(dncResponses[i].IntervieweeId);
      }

      if ((dncResponses[i]?.OfficeId && !companyIdTracker.includes(dncResponses[i].OfficeId))) {
        companyIdFilter.push({
          text: dncResponses[i].OfficeId,
          value: dncResponses[i].OfficeId
        });
        companyIdTracker.push(dncResponses[i].OfficeId);
      }

      if ((dncResponses[i]?.OfficeName && !companyNameTracker.includes(dncResponses[i].OfficeName))) {
        companyNameFilter.push({
          text: dncResponses[i].OfficeName,
          value: dncResponses[i].OfficeName
        });
        companyNameTracker.push(dncResponses[i].OfficeName);
      }

      if ((dncResponses[i]?.FirstName && !firstNameTracker.includes(dncResponses[i].FirstName))) {
        firstNameFilter.push({
          text: dncResponses[i].FirstName,
          value: dncResponses[i].FirstName
        });
        firstNameTracker.push(dncResponses[i].FirstName);
      }

      if ((dncResponses[i]?.LastName && !lastNameTracker.includes(dncResponses[i].LastName))) {
        lastNameFilter.push({
          text: dncResponses[i].LastName,
          value: dncResponses[i].LastName
        });
        lastNameTracker.push(dncResponses[i].LastName);
      }

      if ((dncResponses[i]?.InterviewStatusId && !interviewStatusIdTracker.includes(dncResponses[i].InterviewStatusId))) {
        interviewStatusIdFilter.push({
          text: dncResponses[i].InterviewStatusId,
          value: dncResponses[i].InterviewStatusId
        });
        interviewStatusIdTracker.push(dncResponses[i].InterviewStatusId);
      }

      if ((dncResponses[i]?.TextStatus && !textStatusTracker.includes(dncResponses[i].TextStatus))) {
        textStatusFilter.push({
          text: dncResponses[i].TextStatus,
          value: dncResponses[i].TextStatus
        });
        textStatusTracker.push(dncResponses[i].TextStatus);
      }

      if ((dncResponses[i]?.CorporationId && !companyGroupIdTracker.includes(dncResponses[i].CorporationId))) {
        companyGroupIdFilter.push({
          text: dncResponses[i].CorporationId,
          value: dncResponses[i].CorporationId
        });
        companyGroupIdTracker.push(dncResponses[i].CorporationId);
      }

      if ((dncResponses[i]?.CorporateName && !companyGroupNameTracker.includes(dncResponses[i].CorporateName))) {
        companyGroupNameFilter.push({
          text: dncResponses[i].CorporateName,
          value: dncResponses[i].CorporateName
        });
        companyGroupNameTracker.push(dncResponses[i].CorporateName);
      }

      if ((dncResponses[i]?.RegionId && !regionIdTracker.includes(dncResponses[i].RegionId))) {
        regionIdFilter.push({
          text: dncResponses[i].RegionId,
          value: dncResponses[i].RegionId
        });
        regionIdTracker.push(dncResponses[i].RegionId);
      }

      if ((dncResponses[i]?.RegionName && !regionNameTracker.includes(dncResponses[i].RegionName))) {
        regionNameFilter.push({
          text: dncResponses[i].RegionName,
          value: dncResponses[i].RegionName
        });
        regionNameTracker.push(dncResponses[i].RegionName);
      }

      if ((dncResponses[i]?.FranchiseId && !franchiseIdTracker.includes(dncResponses[i].FranchiseId))) {
        franchiseIdFilter.push({
          text: dncResponses[i].FranchiseId,
          value: dncResponses[i].FranchiseId
        });
        franchiseIdTracker.push(dncResponses[i].FranchiseId);
      }

      if ((dncResponses[i]?.OfficeGeographicalState && !officeGeographicalStateTracker.includes(dncResponses[i].OfficeGeographicalState))) {
        officeGeographicalStateFilter.push({
          text: dncResponses[i].OfficeGeographicalState,
          value: dncResponses[i].OfficeGeographicalState
        });
        officeGeographicalStateTracker.push(dncResponses[i].OfficeGeographicalState);
      }

      if ((dncResponses[i]?.IntervieweeDNC && !intervieweeDNCTracker.includes(dncResponses[i].IntervieweeDNC))) {
        intervieweeDNCFilter.push({
          text: dncResponses[i].IntervieweeDNC,
          value: dncResponses[i].IntervieweeDNC
        });
        intervieweeDNCTracker.push(dncResponses[i].IntervieweeDNC);
      }

      if ((formattedBeginDate && !startedAtTracker.includes(formattedBeginDate))) {
        startedAtFilter.push({
          text: formattedBeginDate,
          value: formattedBeginDate
        });
        startedAtTracker.push(formattedBeginDate);
      }

      if ((formattedEndDate && !endDateTracker.includes(formattedEndDate))) {
        endDateFilter.push({
          text: formattedEndDate,
          value: formattedEndDate
        });
        endDateTracker.push(formattedEndDate);
      }
    }

    const cexColumns = [
      {
        title: 'Interviewee Id',
        dataIndex: 'interviewId',
        editable: false,
        filterSearch: true,
        filters: [
          ...intervieweeIdFilter
        ],
        onFilter: (value, record) => record.interviewId?.indexOf(value) === 0,
        sorter: (a, b) => a.interviewId.length - b.interviewId.length,
      },
      {
        title: 'HCP Office Number',
        dataIndex: 'hcpOfficeNumber',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...companyIdFilter
        ],        
        onFilter: (value, record) => record.hcpOfficeNumber?.indexOf(value) === 0,
        sorter: (a, b) => a.hcpOfficeNumber - b.hcpOfficeNumber,
      },
      {
        title: 'Office Name',
        dataIndex: 'officeName',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...companyNameFilter
        ],
        onFilter: (value, record) => record.officeName?.indexOf(value) === 0,
        sorter: (a, b) => a.officeName.localeCompare(b.officeName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        editable: false,
        filterSearch: true,
        filters: [
          ...firstNameFilter
        ],
        onFilter: (value, record) => record.firstName?.indexOf(value) === 0,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        editable: false,
        filterSearch: true,
        filters: [
          ...lastNameFilter
        ],
        onFilter: (value, record) => record.lastName?.indexOf(value) === 0,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortDirections: ["ascend", "descend"],
      },           
      {
        title: 'Interview Status Id',
        dataIndex: 'interviewStatusId',
        editable: false,
        filterSearch: true,
        filters: [
          ...interviewStatusIdFilter
        ],
        onFilter: (value, record) => record.interviewStatusId?.indexOf(value) === 0,
        sorter: (a, b) => a.interviewStatusId.localeCompare(b.interviewStatusId),
        sortDirections: ["ascend", "descend"],
      },                
      {
        title: 'Text Status',
        dataIndex: 'textStatus',
        editable: false,
        filterSearch: true,
        filters: [
          ...textStatusFilter
        ],
        onFilter: (value, record) => record.textStatus?.indexOf(value) === 0,
        sorter: (a, b) => a.textStatus.localeCompare(b.textStatus),
        sortDirections: ["ascend", "descend"],
      },                     
      {
        title: 'Practice Id',
        dataIndex: 'companyGroupId',
        editable: false,
        filterSearch: true,
        filters: [
          ...companyGroupIdFilter
        ],
        onFilter: (value, record) => record.companyGroupId?.indexOf(value) === 0,
        sorter: (a, b) => a.companyGroupId.localeCompare(b.companyGroupId),
        sortDirections: ["ascend", "descend"],
      },                          
      {
        title: 'Practice Name',
        dataIndex: 'companyGroupName',
        editable: false,
        filterSearch: true,
        filters: [
          ...companyGroupNameFilter
        ],
        onFilter: (value, record) => record.companyGroupName?.indexOf(value) === 0,
        sorter: (a, b) => a.companyGroupName.localeCompare(b.companyGroupName),
        sortDirections: ["ascend", "descend"],
      },                     
      {
        title: 'Region Id',
        dataIndex: 'regionId',
        editable: false,
        filterSearch: true,
        filters: [
          ...regionIdFilter
        ],
        onFilter: (value, record) => record.regionId?.indexOf(value) === 0,
        sorter: (a, b) => a.regionId.localeCompare(b.regionId),
        sortDirections: ["ascend", "descend"],
      },                          
      {
        title: 'Region Name',
        dataIndex: 'regionName',
        editable: false,
        filterSearch: true,
        filters: [
          ...regionNameFilter
        ],
        onFilter: (value, record) => record.regionName?.indexOf(value) === 0,
        sorter: (a, b) => a.regionName.localeCompare(b.regionName),
        sortDirections: ["ascend", "descend"],
      },                         
      {
        title: 'Franchise Id',
        dataIndex: 'franchiseId',
        editable: false,
        filterSearch: true,
        filters: [
          ...franchiseIdFilter
        ],
        onFilter: (value, record) => record.franchiseId?.indexOf(value) === 0,
        sorter: (a, b) => a.franchiseId.localeCompare(b.franchiseId),
        sortDirections: ["ascend", "descend"],
      },                               
      {
        title: 'Office Geographical State',
        dataIndex: 'officeGeographicalState',
        editable: false,
        filterSearch: true,
        filters: [
          ...officeGeographicalStateFilter
        ],
        onFilter: (value, record) => record.officeGeographicalState?.indexOf(value) === 0,
        sorter: (a, b) => a.officeGeographicalState.localeCompare(b.officeGeographicalState),
        sortDirections: ["ascend", "descend"],
      },                               
      {
        title: 'Interviewee DNC',
        dataIndex: 'intervieweeDNC',
        editable: false,
        filterSearch: true,
        filters: [
          ...intervieweeDNCFilter
        ],
        onFilter: (value, record) => record.intervieweeDNC?.indexOf(value) === 0,
        sorter: (a, b) => a.intervieweeDNC.localeCompare(b.intervieweeDNC),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: 'Interview Begin Date',
        dataIndex: 'intervieweeBeginDate',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...startedAtFilter
        ],
        onFilter: (value, record) => record.intervieweeBeginDate.startsWith(value),
        sorter: (a, b) => new Date(a.intervieweeBeginDate) - new Date(b.intervieweeBeginDate),
      },
      {
        title: 'Interview End Date',
        dataIndex: 'intervieweeEndDate',
        editable: false,
        filterMode: 'menu',
        filterSearch: true,
        filters: [
          ...endDateFilter
        ],
        onFilter: (value, record) => record.intervieweeEndDate.startsWith(value),
        sorter: (a, b) => new Date(a.intervieweeEndDate) - new Date(b.intervieweeEndDate),
      },
    ];


    return {
      data: cexResults,
      columns: cexColumns
    };

  }
}

export const getToolTipInformationBasedOnGoals = (goalType, goalsData) => {

  if(goalsData || goalsData?.length > 0){
    if(goalType === companyConst.NPS){

      if(goalsData?.NpsGoalStandard && goalsData?.NpsGoalMinimum){     
        const expectedGoals = (<div><span>NPS Goal: {goalsData?.NpsGoalStandard}</span><br/><span>NPS Minimum Standard: </span>{goalsData?.NpsGoalMinimum}</div>);
        return expectedGoals;
      }else{
        const expectedGoals = "";
        return expectedGoals;
      }
    }else if(goalType === companyConst.OSAT){  
      if(goalsData?.OsatGoalStandard && goalsData?.OsatGoalMinimum){  
        const expectedGoals = (<div><span>OSAT Goal: {goalsData?.OsatGoalStandard}</span><br/><span>OSAT Minimum Standard: </span>{goalsData?.OsatGoalMinimum}</div>);
        return expectedGoals;
      }else{
        const expectedGoals = "";
        return expectedGoals;
      }
    }else{
      const expectedGoals = "";
      return expectedGoals;
    }
  }else{
    const expectedGoals = "";
    return expectedGoals;
  }

}

export const getTargetClassColorBasedByGoal = (goalType, goalsData, avgScore) => {
  let defaultCssColorClass = 'black';
  let greenCssColorClass = '#3CA292';
  let yellowCssColorClass = '#E5981C';
  let redCssColorClass = '#d17059';

  if(goalsData || goalsData > 0 ){
    if(goalType === companyConst.NPS){
      if(goalsData?.NpsGoalStandard && goalsData?.NpsGoalMinimum){      
        if(avgScore >= goalsData?.NpsGoalStandard){
          return greenCssColorClass;
        }else if(avgScore < goalsData?.NpsGoalStandard && avgScore >= goalsData?.NpsGoalMinimum){
          return yellowCssColorClass;
        }else if (avgScore < goalsData?.NpsGoalMinimum){
          return redCssColorClass;
        }else{
          return defaultCssColorClass;
        }
      }else{
        return defaultCssColorClass;
      }  
    }else if(goalType === companyConst.OSAT){

      if(goalsData?.OsatGoalStandard && goalsData?.OsatGoalMinimum){  
        if(avgScore >= goalsData?.OsatGoalStandard){
          return greenCssColorClass;
        }else if(avgScore < goalsData?.OsatGoalStandard && avgScore >= goalsData?.OsatGoalMinimum){
          return yellowCssColorClass;
        }else if (avgScore < goalsData?.OsatGoalMinimum){
          return redCssColorClass;
        }else{
          return defaultCssColorClass;
        }
      }else{
        return defaultCssColorClass;
      }

    }else{
      return defaultCssColorClass;
    }
  }else{
    return defaultCssColorClass;
  }


}

const ReactPocSharedLogic = {
  createGoalsObjectForDatabaseSubmittion,
  getToolTipInformationBasedOnGoals,
  getTargetClassColorBasedByGoal,
  getCompanyGroupNpsandOsat,
  filterByAssignedPropertyArray,
  handleInternalConfigurationForClientsListForVantageCompany,
  handlePercentageIfNaN,
  handleSurveysConversion,
  handleAllSurveysConversion,
  handleInternalConfigurationForSurveyResponses,
  formatSurveyDate,
  handleSurveyResponseConversion,
  transformRawCompanyGroupAndChildrenInSearchableResultSet,
  handleInternalConfigurationForClientsListForCompany,
  getLastPathInCurrentUrl,
  handleSurveysForCEXReport,
  getMergedObjectArrays,
  handleSurveysForDncReport,
  handleCompanyCompanyGroupGoalsReport,
  transformRawCompanyGroupAndChildrenForGoalsTable,
  getGroupIdsAndOptionsString,
  updateNPSandOSATPropertyByKeyForGoalsForm,
  propagatetUpdateNPSandOSATPropertyToChildrenOfCompanyGroupByKeyForGoalsForm
};

export default ReactPocSharedLogic;
